import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let user = this.authService.getLoggedUser();
    
    if(user.status) {

      let token =  JSON.parse(localStorage.getItem("token")!);

      request = request.clone({

        setHeaders: {

            token: `${token}`

        }

      });

      
    }
    
    return next.handle(request);

  }
}
