<div fxLayout="row wrap">
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="12.5"   class="p-2"> </div>
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="25" fxFlex.md="33.3" fxFlex.sm="30" class="p-2">         
        <mat-card class="gradient-green tile p-2"> 
            <mat-icon>monetization_on</mat-icon> 
             <h2>{{sumPerMonth}} {{currency}}</h2>          
            <p>Montant encaisse le Mois</p>            
        </mat-card>
    </div>
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="25" fxFlex.md="33.3" fxFlex.sm="30" class="p-2">
        <mat-card class="gradient-orange tile p-2">
            <mat-icon>trending_up</mat-icon> 
            <h2>{{sumPerWeek}} {{currency}}</h2>        
            <p>Montant encaisse la semaine</p> 
        </mat-card>
    </div>
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="25" fxFlex.md="33.3" fxFlex.sm="30" class="p-2">        
        <mat-card class="gradient-red tile p-2"> 
            <mat-icon>thumb_up_alt</mat-icon> 
            <h2>{{sumPerDay}} {{currency}}</h2>          
            <p>Montant encaisse par jour</p>            
        </mat-card>
    </div>
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="12.5"   class="p-2"> </div>

    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="12.5"   class="p-2"> </div>
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="25" fxFlex.md="33.3" fxFlex.sm="30" class="p-2">  
        <mat-card class="gradient-green tile p-2">
            <mat-icon>monetization_on</mat-icon> 
            <h2>{{gainPerMonth}} {{currency}}</h2>          
            <p>Gain du mois</p>  
        </mat-card>
    </div>
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="25" fxFlex.md="33.3" fxFlex.sm="30" class="p-2">  
        <mat-card class="gradient-orange  tile p-2">
            <mat-icon>trending_up</mat-icon> 
            <h2>{{gainPerWeek}} {{currency}}</h2>          
            <p>Gain de la semaine</p>  
        </mat-card>
    </div>
    <div fxFlex="100" fxFlex.xl="20" fxFlex.lg="25" fxFlex.md="33.3" fxFlex.sm="30" class="p-2">  
        <mat-card class="gradient-red tile p-2">
            <mat-icon>thumb_up_alt</mat-icon> 
            <h2>{{gainPerDay}} {{currency}}</h2>          
            <p>Gain du jour</p>  
        </mat-card>
    </div>
</div>
