import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, HostListener } from '@angular/core';

@Component({
  selector: 'app-modal-price',
  templateUrl: './modal-price.component.html',
  styleUrls: ['./modal-price.component.scss']
})
export class ModalPriceComponent implements OnInit {

  color: any = 'primary';
  
  public value: number;
  public text: string;
  public choice: string;

  public slider1: any;
  public slider2: any;
  
  constructor(private dialogRef: MatDialogRef<ModalPriceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.initProperty();

  }


  private initProperty() {

    if(this.data != undefined) {

      this.value = this.data.price

      if(this.data.choice != undefined) {
        
        this.choice = this.data.choice

        if(this.data.choice == 'plus') {

          this.slider1 = this.data.price;
          this.slider2 = 10000
          this.text = this.data.price+' XOF et plus';
  
        } else {
  
          this.slider1 = 0;
          this.slider2 = this.data.price
          this.text = this.data.price+' XOF et moins';
  
        }

      } else {

        this.value = 0;

        this.slider1 = 0
        this.slider2 = 10000
      }

    } else {

      this.value = 0;
      
      this.slider1 = 0;
      this.slider2 = 10000;

    }

    // console.log(this.data);

  }

  public sliderChange(currentSlider: string, e: any) {

    if(currentSlider === 'plus') {

      this.text = e.value +' XOF et plus';

    } else {

      this.text = e.value +' XOF et moins';

    }

    this.value = e.value;
    this.choice = currentSlider;

  }

  //Gestion des clics
  @HostListener('document:click')
    clicked() {

    this.dialogRef.backdropClick().subscribe((res:any) => {

      this.dialogRef.close({price: this.value, choice: this.choice, typeFilter: 'price', click: false});

    })

  }

  submitButton() {
    
    this.dialogRef.close({price: this.value, choice: this.choice, typeFilter: 'price', click: true});

  }

}
