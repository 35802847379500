import { AppService } from 'src/app/app.service';
import { ModalMoreFilterComponent } from './../search-car-item/modal-more-filter/modal-more-filter.component';
import { ModalTypeComponent } from './../search-car-item/modal-type/modal-type.component';
import { ModalPriceComponent } from './../search-car-item/modal-price/modal-price.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-car-filter',
  templateUrl: './search-car-filter.component.html',
  styleUrls: ['./search-car-filter.component.scss']
})
export class SearchCarFilterComponent implements OnInit {


  @Input('numberOfResult') numberOfResult: number;
  @Input('types') types: any;
  @Input('model') model: any;
  @Output('itemSearch') itemSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output('buttonRest') buttonRest: EventEmitter<boolean> = new EventEmitter();; 


  public priceSelect: boolean;
  public typeSelect: boolean;
  public moreFilterSelect: boolean;
  public showButtonReset: boolean;

  valuePrice: any;
  valueType: any;
  valueMoreFilter: any;

  equipments: any[];
  motors: any[];


  constructor(public dialog: MatDialog, 
              private appService: AppService) 
  { 
    this.equipments = this.appService.getEquipments();
    this.motors = this.appService.getMotorType();
  }

  ngOnInit(): void {

    this.initProperty();

  }

  
  private initProperty() {

    this.priceSelect = false;
    this.typeSelect = false;
    this.moreFilterSelect = false;
    this.showButtonReset = false;

  }

  public openModalPrice() {

    this.priceSelect = true;

    const dialogPrice = this.appService.openDialogGalsen(ModalPriceComponent, '328px', '185px', '50px', this.valuePrice)

    dialogPrice.afterClosed().subscribe((res) => {

      this.priceSelect = false;

      if(res != undefined) {

        if(res.click) {

          this.itemSearch.emit(res);

          if(!this.showButtonReset) {

            this.showButtonReset = true;
  
          }

        }

        this.valuePrice = res;

      }

    })

  }

  public openModalType() {

    this.typeSelect = true;
    const dialogType = this.appService.openDialogGalsen(ModalTypeComponent, '600px', '0px', '0px', {tabTypes: this.types, formType:this.valueType, checkValue:this.model??null})

    dialogType.afterClosed().subscribe((res) => {

      this.typeSelect = false;

      if(res != undefined) {

        if(res.click) {

          this.itemSearch.emit(res);
          if(!this.showButtonReset) {

            this.showButtonReset = true;
            
          }

        }

        this.valueType = res['type'];
        if(!res.type.find((elt:string)=> elt == this.model))
        {
          this.model=null;
        }

      }
      else{
        this.model=null;
      }

    })

  }


  public openModalMoreFilter() {

    this.moreFilterSelect = true;

    const dialogMoreFilter = this.appService.openDialogGalsen(ModalMoreFilterComponent, '600px', '185px', '290px', {formMoreFilter: this.valueMoreFilter, equipments: this.equipments, motors: this.motors})

    dialogMoreFilter.afterClosed().subscribe(res => {

      this.moreFilterSelect = false;

      if(res != undefined) {

        if(res.click) {

          this.itemSearch.emit(res);

          if(!this.showButtonReset) {

            this.showButtonReset = true;
            
          }

        }

        this.valueMoreFilter = res['moreFilter'];

      }


    })

  }

  //Permet de contrôler la visiblité du button de reintialisation
  public resetFliter() {

    this.showButtonReset = false;

    this.valuePrice = null;
    this.valueType = null;
    this.valueMoreFilter = null;
    this.model=null;

    this.types = this.types.filter(function(t: any) {
          
      t.completed = false;

      return t;

    });

    this.equipments = this.equipments.filter(function(e: any) {
          
      e.completed = false;

      return e;

    });

    this.motors = this.motors.filter(function(m: any) {
          
      m.completed = false;

      return m;

    });

    this.buttonRest.emit(true);

  }

}
