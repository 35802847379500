import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Component, HostListener, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-modal-more-filter',
  templateUrl: './modal-more-filter.component.html',
  styleUrls: ['./modal-more-filter.component.scss']
})
export class ModalMoreFilterComponent implements OnInit {

  form: FormGroup;

  color: any = 'primary';

  public valueCapacity: number;
  public intervalCapacity: string | null;

  public valuePlace: number;
  public intervalPlace: string | null;

  public equipments: any[] = [];
  public motorType: any[] = [];

  public textCapacity: string;
  public textNumberPlace : string;

  public showButtonSubmit: boolean;

  public sliderC1: any;
  public sliderC2: any;

  public sliderP1: any;
  public sliderP2: any;

  constructor(public dialogRef: MatDialogRef<ModalMoreFilterComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private breakpointObserver: BreakpointObserver) {

   }

  ngOnInit(): void {

    this.initProperty();

  }

  private initProperty() {

    this.equipments = this.data.equipments;

    this.motorType = this.data.motors;

    this.form = this.fb.group({
      valueCapacity: [null],
      intervalCapacity: [null],
      valuePlace: [null],
      intervalPlace: [null],
      checkArrayEquipment : this.fb.array([]),
      vitesse: [false],
      checkArrayMotor: this.fb.array([]),
      year: [null]
    })

    // this.showButtonSubmit = false;

    this.valueCapacity = 0;
    this.valuePlace = 0;

    // console.log(this.data, this.equipments);

    if(this.data['formMoreFilter'] != undefined) {

      let data = this.data['formMoreFilter'];

      //Capacity
      if(data['intervalCapacity'] != undefined) {

        this.valueCapacity = data['valueCapacity'];
        this.intervalCapacity = data['intervalCapacity'];

        if(data['intervalCapacity'] == 'plus') {

          this.textCapacity = data['valueCapacity'] +' m³ et plus'
          this.sliderC1 = data['valueCapacity'];
          this.sliderC2 = 30

        } else {

          this.textCapacity = data['valueCapacity'] +' m³ et moins'
          this.sliderC1 = 0;
          this.sliderC2 = data['valueCapacity']

        }

      } else {

        this.sliderC1 = 0;
        this.sliderC2 = 30;

      }


      //Number Places
      if(data['intervalPlace'] != undefined) {

        this.valuePlace =data['valuePlace'];
        this.intervalPlace = data['intervalPlace'];

        if(data['intervalPlace'] == 'plus') {

          this.textNumberPlace = data['valuePlace'] +' place et plus'
          this.sliderP1 = data['valuePlace'];
          this.sliderP2 = 9

        } else {

          this.textNumberPlace = data['valueCapacity'] +' place et moins'
          this.sliderP1 = 0;
          this.sliderP2 = data['valuePlace']

        }

      } else {

        this.sliderP1 = 0;
        this.sliderP2 = 9;

      }


      //Equipements
      if(data['checkArrayEquipment'] != undefined) {
      
        const valueEquip: FormArray = new FormArray([...data['checkArrayEquipment'].map((item: any) => new FormControl(item))]
        );
  
        this.form.setControl('checkArrayEquipment', valueEquip);
        
      }


      //Boîte à vitesse
      if(data['vitesse']) {

        this.form.patchValue({vitesse: true})

      }


      //Type Motors
      if(data['checkArrayMotor'] != undefined) {
      
        const valueMotors: FormArray = new FormArray([...data['checkArrayMotor'].map((item: any) => new FormControl(item))]
        );
  
        this.form.setControl('checkArrayMotor', valueMotors);
        
      }


      //Year
      if(data['year'] != undefined) {
        
        this.form.patchValue({year: data['year']})

      }

    } else {

      this.sliderC1 = 0;
      this.sliderC2 = 30;

      this.sliderP1 = 0;
      this.sliderP2 = 9;

    }

    this.showButtonSubmit = this.showButtonMethode();


  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  //Permet de gérer la taille courante de l'écran
  @HostListener("window:resize")
  onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    
    this.breakpointObserver.observe(["(max-width:480px)"]).subscribe((result: BreakpointState) => {
       
        if(result.matches) {
          // console.log('je suis dans 480 if')
          this.dialogRef.updatePosition({left: '20px', top: '90px'})
  
        } else {

          this.breakpointObserver.observe(["(max-width:768px)"]).subscribe((resultTwo: BreakpointState) => {

            if(resultTwo.matches) {
              // console.log('je suis dans 768 if')
              this.dialogRef.updatePosition({left: '20px', top: '90px'});
              
            } else {

              this.breakpointObserver.observe(["(max-width:1600px)"]).subscribe((resultThree: BreakpointState) => {

                if(resultThree.matches) {
                  // console.log('je suis dans 1600 if')
                  this.dialogRef.updatePosition({left: '290px', top: '55px'});

                }
              })

              // this.dialogRef.updatePosition({top: '185px', left: '480px'})
              // this.dialogRef.updatePosition({top: '0px', left: '0px'})
              
            }
          })
  
        }
  
    })
  }

  public sliderChange(currentSlider: string, e: any) {

    switch (currentSlider) {

      case 'c1':
        this.textCapacity = e.value +' m³ et plus'
        this.valueCapacity = e.value;
        this.intervalCapacity = 'plus'
        break;
      
      case 'c2':
          this.textCapacity = e.value +' m³ et moins'
          this.valueCapacity = e.value;
          this.intervalCapacity = 'moins'
          break;

      case 'p1':
          this.textNumberPlace = e.value +' place et plus'
          this.valuePlace = e.value;
          this.intervalPlace = 'plus';
          break;
          
      case 'p2':
          this.textNumberPlace = e.value +' place et moins'
          this.valuePlace = e.value;
          this.intervalPlace = 'moins';
          break;

      default:
        break;

    }
    
  }

  public onCheckboxChange(key: string, e:any) {

    if(key === 'eq') {
        
      let currentEquipment = this.equipments.find((equip: any) => equip.id == e.source.value);

      currentEquipment.completed = e.checked;
      
        const checkArray: FormArray = this.form.get('checkArrayEquipment') as FormArray;

        if(e.checked) {

          checkArray.push(new FormControl(e.source.value));

        } else {

          let i: number = 0;

          checkArray.controls.forEach((item: any) => {

            if(item.value == e.source.value) {

              checkArray.removeAt(i);

              return ;

            }

            i++
          })
        }

    } else {

      let currentMotors = this.motorType.find((motor: any) => motor.value.trim().toLowerCase() == e.source.value.trim().toLowerCase());

      currentMotors.completed = e.checked;

      const checkArray: FormArray = this.form.get('checkArrayMotor') as FormArray;

      if(e.checked) {

        checkArray.push(new FormControl(e.source.value));

      } else {

        let i: number = 0;

        checkArray.controls.forEach((item: any) => {

          if(item.value == e.source.value) {

            checkArray.removeAt(i);

            return ;

          }

            i++
        })
      }

    }

    this.showButtonSubmit = this.showButtonMethode();
    
  }

  //Cette methode permet d'ecouter les changement du slideToggle
  public onSlideToggleChange() {

    this.showButtonSubmit = this.showButtonMethode();

  }

  //Cette methode permet d'ecouter les changement des differents radio button
  public onRadioButton() {

    this.showButtonSubmit = this.showButtonMethode();

  }

  //Permet de contrôller le clic à interieur du modal dialog
  @HostListener('click', ['$event'])
  onInsideClick() {
    
    if(this.valueCapacity != 0   || this.valuePlace != 0  || this.form.get('checkArrayEquipment')?.value.length > 0 ||
      this.form.get('checkArrayMotor')?.value.length > 0 || this.form.get('vitesse')?.value ||  this.form.get('year')?.value != null) {

        this.showButtonSubmit = true;

    } else {
      
      this.showButtonSubmit = false;
      this.intervalCapacity = null;
      this.intervalPlace = null;
      
    }
  }

   //Gestion des clics
   @HostListener('document:click')
   clicked() {
    
   this.dialogRef.backdropClick().subscribe((res:any) => {

    this.form.patchValue(
      {
        valueCapacity: this.valueCapacity,
        intervalCapacity: this.intervalCapacity,
        valuePlace: this.valuePlace,
        intervalPlace: this.intervalPlace
      }
    )

    this.dialogRef.close({moreFilter: this.form.value, typeFilter: 'moreFilter', click: false});

   })

 }

  
  submitForm() {

    this.form.patchValue(
      {
        valueCapacity: this.valueCapacity,
        intervalCapacity: this.intervalCapacity,
        valuePlace: this.valuePlace,
        intervalPlace: this.intervalPlace
      }
    )

    this.dialogRef.close({moreFilter: this.form.value, typeFilter: 'moreFilter', click: true});


  }

  private showButtonMethode() {

    if(this.valueCapacity != 0   || this.valuePlace != 0  || this.form.get('checkArrayEquipment')?.value.length > 0 ||
      this.form.get('checkArrayMotor')?.value.length > 0 || this.form.get('vitesse')?.value ||  this.form.get('year')?.value != null) {

        return true;

    } else {
        
        return false;
        
    }

  }

}
