import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HostService } from 'src/app/services/host.service';
import { AllVehicleRatingsComponent } from './dialog/all-vehicle-ratings/all-vehicle-ratings.component';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {
  @Input('menuItemId') menuItemId = 0;
  @Input('notations') notations: any[];
  @Input('avgNote') avgNote: number;
  @Input('sumNote') sumNote: number;

  constructor(public host: HostService, private appService: AppService) { }

  getAllNotation() {

    let data = {
      avgNote: this.avgNote,
      sumNote: this.sumNote,
      key: 'car'
    }
    
    const dialogRef = this.appService.openDialog(AllVehicleRatingsComponent, data, 'theme-dialog', '500px');
    
  }

}