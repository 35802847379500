<!-- <div class="header-image-wrapper" [dir]="(settings.rtl) ? 'rtl' : 'ltr'" [class.fullscreen]="fullscreen" [ngClass]="'toolbar-'+settings.toolbar">
    <div class="bg" [style.background-image]="bgImage" [class.bg-anime]="bgImageAnimate"></div>
    <div class="mask"></div>
    <div class="header-image-content" fxLayout="column" fxLayoutAlign="center center" [class.offset-bottom]="contentOffsetToTop" [class.home-page]="isHomePage" [style.min-height.px]="contentMinHeight">
        <h1 *ngIf="title" class="title">{{title}}</h1>
        <p *ngIf="desc" class="desc">{{desc}}</p>
        <app-header-search *ngIf="menuSearch"></app-header-search>
    </div>
</div> -->


<div class="header-image-wrapper" [dir]="(settings.rtl) ? 'rtl' : 'ltr'" [class.fullscreen]="fullscreen" [ngClass]="'toolbar-'+settings.toolbar">
    <div class="bg" [style.background-image]="bgImage" [class.bg-anime]="bgImageAnimate"></div>
    <div class="mask"></div>
    <div class="header-image-content" fxLayout="column" fxLayoutAlign="center center" [class.offset-bottom]="contentOffsetToTop" [class.home-page]="isHomePage" [style.min-height.px]="contentMinHeight">
        <h1 *ngIf="title" class="title">{{title}}</h1>
        <p *ngIf="desc" class="desc">{{desc}}</p>
        <app-header-search *ngIf="menuSearch"></app-header-search>        
    </div>
</div>