import { I } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AssetService } from './asset.service';
import { PaymentService } from './payment.service';
import { RentingService } from './renting.service';

@Injectable({
  providedIn: 'root'
})
export class LocationSmartService {

  public response: any;

  constructor(private paymentService: PaymentService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private rentingService: RentingService,
              private datePipe: DatePipe,
              private assetService: AssetService) { }

  /**
   * Paiement d'une location
   * 
   * @param idRenting 
   * @param paymentMethod 
   */
  payment(idRenting: number, paymentMethod: string) {

    this.paymentService.paymentOfRentingExist(idRenting).subscribe((value: any) => {

      // console.log(value);
  
      if(value.val == 1) {
  
        this.toastr.show(
          'Vous avez déjà effectué le paiement de cette location',
          'Paiement evident',
          {
            timeOut: 6000,
            closeButton: true,
          } as IndividualConfig,
          'toast-' + 'warning'
        )
  
      } else {

        if(paymentMethod == "Paydunya") {

          this.paymentService.generateUrlPayment(idRenting, paymentMethod);

        } else if(paymentMethod == "Wave") {

          this.paymentService.generateUrlPayment(idRenting, paymentMethod);

        } else {

          this.toastr.show(
            'Nous sommes encours de développement',
            'En cours de réalisation',
            {
              timeOut: 6000,
              closeButton: true,
            } as IndividualConfig,
            'toast-' + 'warning'
          )
          
          
        }
  
      }

    })

  }


  /**
   * Paiement d'une prolongation
   * 
   * @param idExtension 
   * @param paymentMethod 
   */
  paymentExtension(idExtension: number, paymentMethod: string) {

    this.paymentService.paymentOfExtensionExist(idExtension).subscribe((value: any) => {

      // console.log(value);
  
      if(value.val == 1) {
  
        this.toastr.show(
          'Vous avez déjà effectué le paiement de cette prolongation',
          'Paiement evident',
          {
            timeOut: 6000,
            closeButton: true,
          } as IndividualConfig,
          'toast-' + 'warning'
        )
  
      } else {

        if(paymentMethod == "Paydunya") {

          this.paymentService.generateUrlPaymentForExtension(idExtension, paymentMethod);

        } else if(paymentMethod == "Wave") {

          this.paymentService.generateUrlPaymentForExtension(idExtension, paymentMethod);

        } else {

          this.toastr.show(
            'Nous sommes encours de développement',
            'En cours de réalisation',
            {
              timeOut: 6000,
              closeButton: true,
            } as IndividualConfig,
            'toast-' + 'warning'
          )
          
          
        }
  
      }

    })

  }

  changeStatus(renting: any, status: string, key: string): boolean{


    if (renting.renting_status != status) {
      let obj = {
        id: renting.id,
        status: status
      };

      this.spinner.show();

      if(renting.renting_status == "PAYER" && status == "ANNULER") {

        let res = this.changeStatusAfterPayment(renting, status, key);

        this.spinner.hide();

        return res;

        
      } else {
        
        this.rentingService
        .changeStatus(obj)
        .subscribe((response: any) => {

          this.spinner.hide();
          
          this.toastr.show("Le status de la réservation du " + renting.renting_date_departure +" au " + renting.renting_date_return + " concernant le véhicule " + renting.mark_lib + " " +renting.mod_lib +" a été modifié","Status Changé",
            {
              timeOut: 5000,
              closeButton: true,
            } as IndividualConfig,
            "toast-" + "success"
          );
        });

        return true;

      }
      

    } else {

      this.toastr.show("Impossible d'effectuer cette action","Erreur Erreur",
        {
          timeOut: 5000,
          closeButton: true,
        } as IndividualConfig,
        "toast-" + "error"
      );

      return false;

    }

    
  }

  changeStatusAfterPayment(renting: any, status: string, key: string): boolean{

    this.spinner.show();
    
    if(key === "owner") {

      let obj = {
        'key': key,
        'idRenting': renting.id,
      } 

      this.assetService.create(obj).subscribe((response :any) => {

        this.response = response;

        this.toastr.show("Le status de la réservation du " + renting.renting_date_departure +" au " + renting.renting_date_return + " concernant le véhicule " + renting.mark_lib + " " +renting.mod_lib +" a été modifié","Status Changé",
        {
          timeOut: 5000,
          closeButton: true,
        } as IndividualConfig,
        "toast-" + "success"
        );

      })

      return true;

    } else {

      //Nous recuperons l'heure de départ de la location
      let tabHour = renting.renting_hour_departure.split('-');

      let tabHourTwo = tabHour[0].split('h');

      let finalHour = tabHourTwo[0]+':00:00';

      //Concatenation de la date de depart et de l'heure de depart
      let finalDate = new Date(renting.renting_date_departure+' '+finalHour);

      let today = new Date();

      //Nombre de heures écoulent entre la date d'aujourd'hui et la date de début de la location
      let nbHours = ((today.getTime() -  finalDate.getTime()) / ( 1000 * 60 * 60 )).toFixed(0);
      
      //Jour +1 de la date de départ
      let nextDate = new Date(renting.renting_date_departure+' '+finalHour);

      nextDate.setDate((new Date(renting.renting_date_departure+' '+finalHour)).getDate() + 1)

      // console.log( nextDate, today,nbHours);

      if(this.datePipe.transform(nextDate, 'yyyy-MM-dd')! <=  this.datePipe.transform(today, 'yyyy-MM-dd')! && Number(nbHours) >= Number('24')) {
        
        this.toastr.show("Vous n'avez plus la possibilité d'annuler la réservation du " + renting.renting_date_departure +" au " + renting.renting_date_return + " concernant le véhicule " + renting.mark_lib + " " +renting.mod_lib +"","Impossible",
          {
            timeOut: 5000,
            closeButton: true,
          } as IndividualConfig,
          "toast-" + "error"
        );

        return false;

      } else {

        let obj: Object;

        if(renting.renting_date_departure === this.datePipe.transform(today, 'yyyy-MM-dd')!) {

          obj = {
            'key': key,
            'period': 'equal',
            'idRenting': renting.id,
          }

        } else {

          if(Number(nbHours) < Number('0')) {

            obj = {
              'key': key,
              'period': 'before',
              'idRenting': renting.id,
            } 

          } else {

            obj = {
              'key': key,
              'period': 'after',
              'idRenting': renting.id,
            } 

          }

        }

        this.assetService.create(obj).subscribe((response: any) => {

          this.response = response;

          this.toastr.show("Le status de la réservation du " + renting.renting_date_departure +" au " + renting.renting_date_return + " concernant le véhicule " + renting.mark_lib + " " +renting.mod_lib +" a été modifié","Status Changé",
            {
              timeOut: 5000,
              closeButton: true,
            } as IndividualConfig,
            "toast-" + "success"
          );

        });            

        return true;

      }

    }

  }

}
