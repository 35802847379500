import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'UserSearchPipe', pure: false })

export class UserSearchPipe implements PipeTransform {
  transform(value:any, args?:any) {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter((user:any) => {
        //console.log(user)
        if (user.email) {
          return user.email.search(searchText) !== -1;
        }else if (user.user_phone) { 
        return user.user_phone.search(searchText) !== -1;
        }else{
          return user.user_name.search(searchText) !== -1;
        }
      });
    }
  }
}