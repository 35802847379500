import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataSearchService } from 'src/app/services/data-search.service';
import { HostService } from 'src/app/services/host.service';
import { NotationCarService } from 'src/app/services/notation-car.service';

@Component({
  selector: 'app-all-vehicle-ratings',
  templateUrl: './all-vehicle-ratings.component.html',
  styleUrls: ['./all-vehicle-ratings.component.scss']
})
export class AllVehicleRatingsComponent implements OnInit, OnDestroy {

  private notationSubscription: Subscription;

  private idCar: number;
  public notations: any[];

  constructor(public dialogRef: MatDialogRef<AllVehicleRatingsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dataSearchService: DataSearchService,
              private notationCarService: NotationCarService,
              public host: HostService) { }

  ngOnInit(): void {

    this.getNotation();

  }

  getNotation() {

    if(this.data.key == 'car') {

      if(this.dataSearchService.idCar != undefined) {

        this.idCar = this.dataSearchService.idCar;

        this.notationSubscription = this.notationCarService.vehicleRating(this.idCar).subscribe((data: any) => {

          this.notations = data.notations;

          // console.log(data);

        })

      }

    } else {
      
      this.notationSubscription = this.notationCarService.userRating(this.data.idUser).subscribe((data: any) => {

        this.notations = data.notations;

        // console.log(data);

      })

    }

  }

  ngOnDestroy(): void {
    
    this.notationSubscription.unsubscribe();

  }

}
