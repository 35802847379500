import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from "ts-cacheable"
import { Renting } from '../app.models';
import { HostService } from './host.service';

@Injectable({
  providedIn: "root",
})
export class RentingService {

  constructor(private getHost: HostService, private http: HttpClient) {}

  @Cacheable()
  index(status: string) {
    return this.http.get(this.getHost.gethttps() + 'renting/' + status);
  }

  @Cacheable()
  indexAll() {
    return this.http.get(this.getHost.gethttps() + 'index');
  }
  
  indexOn10Years() {

    return this.http.get(this.getHost.gethttps() + 'rentingOn10year');

  }

  create(renting: Renting) {

    return this.http.post(this.getHost.gethttps() + "renting", renting);

  }

  //Location Locataire
  indexLodger(idUser: number) {

    return this.http.get(this.getHost.gethttps() + "rentingLodger/" + idUser);

  }

  //Location Propriètaire
  indexOwner(idUser: number) {

    return this.http.get(this.getHost.gethttps() + "rentingOwner/" + idUser);

  }

  //Change status
  changeStatus(renting: any) {

    return this.http.post(this.getHost.gethttps() + "changeStatusRenting", renting);

  }

  //Change status renting of dashboard
  changeStatusOnDashboard(renting: any) {

    return this.http.post(this.getHost.gethttps() + "changeStatusOnDashboard", renting);

  }

  //Change status
  showInfoUser(value: any) {

    return this.http.post(this.getHost.gethttps() + "showInfoUser", value);

  }

  //Valeur de l'assurance pour chaque location
  @Cacheable()
  valueInsuranceAccordingRenting() {

    return this.http.get(this.getHost.gethttps()+ 'valueInsuranceAccordingRenting');
    
  }

  envoiMailCar(data:any) {
    
    return this.http.post(this.getHost.gethttps() + "envoiMailCar", data);

  }

  rentingConstat(vehiculeId:number)
  {
    return this.http.get(this.getHost.gethttps() + "rentingConstat/"+vehiculeId);
  }

  rentingLitigeOrSinister(vehiculeId:number)
  {
    return this.http.get(this.getHost.gethttps() + "rentingLitigeOrSinister/"+vehiculeId);
  }

}
