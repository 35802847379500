import { Injectable } from '@angular/core';
import { HostService } from './host.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Transfer } from '../app.models';


@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(private getHost: HostService,
              private http: HttpClient) { }

  create(transfer: Transfer) {

    return this.http.post(this.getHost.gethttps() + 'transfer', transfer);

  }


  index() {

    return this.http.get(this.getHost.gethttps() + 'transfer');

  }


  /**
  * Cette methode retourné le montant total des demandes de virements d'un propriètaire
  * 
  * @param obj 
  * @returns 
  */
  sumTransfer(obj: object) {
      
    return this.http.post(this.getHost.gethttps() + "sumTransfer", obj);
    
  }


  /**
   * Retourner tous les demandes de virements d'un utilisateur
   * 
   * @param idUser 
   * @returns 
   */
  transferOfUser(obj: object) {

    return this.http.post(this.getHost.gethttps() + 'transferOfUser', obj);

  }


  /**
   * Cette methode permet de changer le status d'une demande de virements
   * 
   * @param object 
   * @returns 
   */
   changeStatusTransfer(obj: object) {

    return this.http.post(this.getHost.gethttps() + 'changeStatusTransfer', obj);

  }

  /**
   * Cette methode permet le paiement d'une demande de virements
   * 
   * @param object 
   * @returns 
   */
  paymentOfTransfer(obj: object) {

    return this.http.post(this.getHost.gethttps() + 'paymentOfTransfer', obj);

  }

}
