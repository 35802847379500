<h1 mat-dialog-title class="header-dialog"><mat-icon color="primary">filter_alt</mat-icon> Catégories de véhicules</h1>
<div mat-dialog-content>
    <div class="fliter-type">
        <div *ngFor="let type of typeCars" class="fliter-content">
            <mat-checkbox [checked]="model != undefined && model===type.code ? true:type.completed" [value]="type.code" (change)="onCheckboxChange($event)" [color]="color" class="checkbox">{{type.name}}</mat-checkbox>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button class="search-button-disabled" *ngIf="!showButton">Rechercher</button>
    <button class="search-button" *ngIf="showButton" (click)="submitButton()">Rechercher</button>

</div>


<!-- <div *ngFor="let s of sizes" class="{{s.css + ' ' + (prefix + s.id) }}">{{s.name}}</div> -->