<form [formGroup]="form" (ngSubmit)="submit()" class="form-info"  *ngIf="user">
    <div class="text-form">Horaires de remise du véhicule *</div>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Heure de départ</mat-label> 
                <mat-select formControlName="hourStart" required *ngIf="dataSearchService.templateSearchCar.periodStart === 'Avant 12h'"> 
                    <mat-option *ngFor="let hour of hoursBefore" [value]="hour.value">{{hour.text}}</mat-option>
                </mat-select>
                <mat-select formControlName="hourStart" required *ngIf="dataSearchService.templateSearchCar.periodStart === 'Après 12h'"> 
                    <mat-option *ngFor="let hour of hoursAfter" [value]="hour.value">{{hour.text}}</mat-option>
                </mat-select>
                <mat-icon matSuffix class="text-muted">schedule</mat-icon>
                <mat-error *ngIf="form.controls.hourStart.errors?.required">Champ requis</mat-error>
            </mat-form-field>  
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Heure de retour</mat-label> 
                <mat-select formControlName="hourEnd" required *ngIf="dataSearchService.templateSearchCar.periodEnd== 'Avant 12h'"> 
                    <mat-option *ngFor="let hour of hoursBefore" [value]="hour.value">{{hour.text}}</mat-option>
                </mat-select>
                <mat-select formControlName="hourEnd" required *ngIf="dataSearchService.templateSearchCar.periodEnd== 'Après 12h'"> 
                    <mat-option *ngFor="let hour of hoursAfter" [value]="hour.value">{{hour.text}}</mat-option>
                </mat-select>
                <mat-icon matSuffix class="text-muted">schedule</mat-icon>
                <mat-error *ngIf="form.controls.hourEnd.errors?.required">Champ requis</mat-error>
            </mat-form-field>  
        </div>
    </div>
    <div class="text-form message">Message au propriétaire</div>
    <div>
        <mat-form-field appearance="outline" class="w-100">
            <textarea matInput placeholder="Précisez le lieu et l’heure de rendez-vous souhaités. N’oubliez pas les règles élémentaires de politesse et de courtoisie, et évitez les détails trop personnels." formControlName="message" rows="6"></textarea> 
            <!-- <mat-error *ngIf="form.controls.message.errors?.required">Champ requis</mat-error> -->
        </mat-form-field>
    </div>
    <div class="text-form info-user">Informations personnelles *</div>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100"> 
                <mat-label>Prénom</mat-label>
                <input matInput formControlName="surname" required>
                <mat-icon matSuffix class="text-muted">person</mat-icon>
                <mat-error *ngIf="form.controls.surname.errors?.required">Champ requis</mat-error>
            </mat-form-field> 
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100"> 
                <mat-label>Nom</mat-label>
                <input matInput formControlName="name" required>
                <mat-icon matSuffix class="text-muted">person</mat-icon>
                <mat-error *ngIf="form.controls.name.errors?.required">Champ requis</mat-error>
            </mat-form-field> 
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Date de naissance</mat-label>
            <input matInput formControlName="date" required [matDatepicker]="datePicker" appMaskDate [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>  
                <mat-error *ngIf="form.controls.date.errors?.required">Champ requis</mat-error>
            </mat-form-field> 
        </div> 
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1"> 
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Téléphone</mat-label>
                <!-- <input  matInput formControlName="phone" required  type="number" pattern="[8|9|6|7][0-9]{8}"> -->
                <input matInput formControlName="phone" required  >
                <mat-icon  matSuffix class="text-muted">call</mat-icon>
                <mat-error *ngIf="form.controls.phone.errors?.required">Champ requis</mat-error>
                <mat-error *ngIf="form.controls.phone.errors?.pattern"> Veuillez respecter le format demandé (+33|+221|+237 774312783).</mat-error> 
            </mat-form-field> 
        </div>
    </div>
    <div class="text-form info-permis">Permis de conduire *</div>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100"> 
                <mat-label>Numéro de permis</mat-label>
                <input matInput formControlName="permisNumber" required type="text"  minlength="9" maxlength="10">
                <mat-error *ngIf="form.controls.permisNumber.errors?.required">Champ requis</mat-error>
            </mat-form-field> 
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Date d'obtention</mat-label>
            <input matInput formControlName="permisDate" required [matDatepicker]="datePickerPermis" appMaskDate>
            <mat-datepicker-toggle matSuffix [for]="datePickerPermis"></mat-datepicker-toggle>
            <mat-datepicker #datePickerPermis></mat-datepicker>  
                <mat-error *ngIf="form.controls.permisDate.errors?.required">Champ requis</mat-error>
            </mat-form-field> 
        </div> 
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Pays</mat-label> 
                <mat-select formControlName="country" required> 
                    <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls.country.errors?.required">Champ requis</mat-error>
            </mat-form-field>  
        </div>
    </div>
    <div class="button-send">
        <button class="button-submit" type="submit">Envoyer une demande</button>
    </div>
</form>
<ngx-spinner type="ball-clip-rotate-multiple" color="#e91e63" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #e91e63; margin-top: 20px; font-weight: bold;">Loading...</p>
</ngx-spinner>