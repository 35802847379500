
<div fxLayout="row" fxLayoutAlign="center center" class="h-100" style="padding: 20px">
    <form [formGroup]="form" (ngSubmit)="notationCarSubmit()">
    <div class="cont">
        <div class="stars">
            <ngx-star-rating formControlName="rating" [id]="'rating'"></ngx-star-rating>
        </div>
        <div>Note: {{form.value.rating}}</div>
    </div>
    <mat-form-field appearance="outline" class="w-100">
    <mat-label>laissez un message.</mat-label>
    <textarea matInput placeholder="laissez un commentaire"formControlName="notation_description" rows="5"></textarea>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit" class="w-100 uppercase" [disabled]="!actifButtom"><span class="px-3 uppercase">Enregistrer</span></button>
    </form>
</div>
