import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { TemplateSearchCar } from "src/app/app.models";
import { DataSearchService } from "./../../services/data-search.service";
import { DatePipe } from "@angular/common";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { Output, EventEmitter } from "@angular/core";
import { AppService } from 'src/app/app.service';

@Component({
  selector: "app-search-adress",
  templateUrl: "./search-adress.component.html",
  styleUrls: ["./search-adress.component.scss"],
})
export class SearchAdressComponent implements OnInit {
  //Local Variable Defined
  @Output() newItemEvent = new EventEmitter<any>();
  address: string;
  geometry: object;

  options: Options;


  dateStart: any;
  dateEnd: any;
  todayWithPipe: any;
  periodStart?: string;
  periodEnd?: string;
  currentDateFormat?: string;
  templateSearchCar: TemplateSearchCar;

  index: number;

  constructor(
    private appService: AppService
  ) {

    this.options = this.appService.getOptionPlacesMap();
    
  }

  ngOnInit(): void {
    
  }

  public addressChange(address: any) {
    this.address = address.formatted_address;
   let obj = {
      adress: this.address,
      latitude: address.geometry.location.lat(),
      lonitude: address.geometry.location.lng(),
    };

    this.geometry = address.geometry.location;
    console.log(obj);
    this.newItemEvent.emit(obj);

  }


}