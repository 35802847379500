import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private getHost: HostService, private http: HttpClient) {}

  create(asset: object) {

    return this.http.post(this.getHost.gethttps() + 'asset', asset);

  }

  assetOfUser(idUser: number) {

    return this.http.get(this.getHost.gethttps() + 'assetOfUser/'+idUser);

  }
}
