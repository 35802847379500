import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AgmCoreModule } from '@agm/core';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';  
import { FlexLayoutModule } from '@angular/flex-layout';
import { SwiperModule } from 'ngx-swiper-wrapper'; 
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
  suppressScrollX: true               
};

import { SocialLoginComponent } from '../pages/social-login/social-login.component';

//import { SearchAdressComponent } from './search-adress/search-adress.component';

import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module';

import { CoolSocialLoginButtonsModule } from '@angular-cool/social-login-buttons';


import { LogoComponent } from './logo/logo.component';
import { ImagePaiementComponent } from '../../app/compte-utilisateur/mes-paiements/mes-paiements/image-paiement/image-paiement.component';
import { HeaderImageComponent } from './header-image/header-image.component';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { OurAwardsComponent } from './our-awards/our-awards.component';
//import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuItemsCarouselComponent } from './menu-items-carousel/menu-items-carousel.component';
import { CartOverviewComponent } from './cart-overview/cart-overview.component';
import { QuantityCounterComponent } from './quantity-counter/quantity-counter.component';
import { RatingComponent } from './rating/rating.component';
import { CommentsComponent } from './comments/comments.component';
import { MenuItemsToolbarComponent } from './menu-items-toolbar/menu-items-toolbar.component';
import { MenuItemHoverableComponent } from './menu-item-hoverable/menu-item-hoverable.component';
import { OurChefsComponent } from './our-chefs/our-chefs.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { GetAppComponent } from './get-app/get-app.component';
import { ReservationFormComponent } from './reservation-form/reservation-form.component';
import { ReservationDialogComponent } from './reservation-dialog/reservation-dialog.component'; 
import { DialogHeaderControlsComponent } from './dialog-header-controls/dialog-header-controls.component'; 
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component'; 
import { SocialIconsComponent } from './social-icons/social-icons.component';
import { TodayMenuComponent } from './today-menu/today-menu.component';
import { LangComponent } from './lang/lang.component';
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component';
import { HeaderVideoComponent } from './header-video/header-video.component';
import { GetAppGalsenComponent } from './get-app-galsen/get-app-galsen.component';
import { OurServicesGalsenComponent } from './our-services-galsen/our-services-galsen.component';
import { TestimonialsGalsenComponent } from './testimonials-galsen/testimonials-galsen.component';
import { MenuAdvantagesComponent } from './menu-advantages/menu-advantages.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { QuestionInsuranceComponent } from './question-insurance/question-insurance.component';
import { EnQuelquesMotsComponent } from './en-quelques-mots/en-quelques-mots.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { LogoInsuranceComponent } from './logo-insurance/logo-insurance.component';


import localeFr from '@angular/common/locales/fr';
import { MapSenegalComponent } from './map-senegal/map-senegal.component';
import { HeaderSearchFormComponent } from './header-search-form/header-search-form.component';
import { SearchCarFilterComponent } from './search-car-filter/search-car-filter.component';
import { ModalPriceComponent } from './search-car-item/modal-price/modal-price.component';
import { ModalTypeComponent } from './search-car-item/modal-type/modal-type.component';
import { ModalMoreFilterComponent } from './search-car-item/modal-more-filter/modal-more-filter.component';
import { SearchCarListComponent } from './search-car-list/search-car-list.component';
import { SearchAdressComponent } from './search-adress/search-adress.component';
import { SearchCarComponent } from './search-car/search-car.component';
import { SearchAdresseActuelComponent } from './search-adresse-actuel/search-adresse-actuel.component';
import { SearchAdresseAccidentComponent } from './search-adresse-accident/search-adresse-accident.component';
import { DetailLocationComponent } from './detail-location/detail-location.component';
import { LatestLocationFormComponent } from './latest-location-form/latest-location-form.component';
import { RentingMessageComponent } from './renting-message/renting-message.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TransferTableSharedComponent } from './transfer-table-shared/transfer-table-shared.component';
import { ReceptionTableSharedComponent } from './reception-table-shared/reception-table-shared.component';
import { MenuItemHomeComponent } from './menu-item-home/menu-item-home.component';
import { NgxStarRatingModule } from "ngx-star-rating";
import { NgxSpinnerModule } from 'ngx-spinner';
import { RentingListComponent } from './renting-list/renting-list.component';
import { ChoosePaymentMethodComponent } from './choose-payment-method/choose-payment-method.component';
import { AllVehicleRatingsComponent } from './comments/dialog/all-vehicle-ratings/all-vehicle-ratings.component';
import { RentingExtensionComponent } from './renting-extension/renting-extension.component';
import { AboutUmexiComponent } from './about-umexi/about-umexi.component';
// import { NgxMaterialRatingModule } from "ngx-material-rating";

registerLocaleData(localeFr, 'fr');


@NgModule({
  declarations: [
    AboutUmexiComponent,
    LogoComponent,
    ImagePaiementComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    OurServicesComponent,
    OurAwardsComponent,
    // GetInTouchComponent,
    MenuItemComponent,

    //LoginFacebookComponent,
    SearchAdressComponent,
    SocialLoginComponent,

    MenuItemsCarouselComponent,
    CartOverviewComponent,
    QuantityCounterComponent,
    RatingComponent,
    CommentsComponent,
    MenuItemsToolbarComponent,
    MenuItemHoverableComponent,
    OurChefsComponent,
    TimelineComponent,
    TestimonialsComponent,
    GetAppComponent,
    ReservationFormComponent,
    ReservationDialogComponent,
    DialogHeaderControlsComponent,
    ImageUploadComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SocialIconsComponent,
    TodayMenuComponent,
    LangComponent,
    OrderDetailsDialogComponent,
    HeaderVideoComponent,
    GetAppGalsenComponent,
    OurServicesGalsenComponent,
    TestimonialsGalsenComponent,
    MenuAdvantagesComponent,
    HeaderSearchComponent,
    QuestionInsuranceComponent,
    EnQuelquesMotsComponent,
    LogoInsuranceComponent,
    MapSenegalComponent,
    HeaderSearchFormComponent,
    SearchCarFilterComponent,
    ModalPriceComponent,
    ModalTypeComponent,
    ModalMoreFilterComponent,
    SearchCarListComponent,
    SearchAdressComponent,
    SearchCarComponent,
    SearchAdresseActuelComponent,
    SearchAdresseAccidentComponent,
    DetailLocationComponent,
    LatestLocationFormComponent,
    RentingMessageComponent,
    TransferTableSharedComponent,
    ReceptionTableSharedComponent,
    MenuItemHomeComponent,
    RentingListComponent,
    ChoosePaymentMethodComponent,
    AllVehicleRatingsComponent,
    RentingExtensionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    CoolSocialLoginButtonsModule,
    // NgxMaterialRatingModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    SwiperModule,
    TranslateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule,
    NgxStarRatingModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDEHxGTghDs8dCFEYCIFcSp5gk-s2OQm6w",
      libraries: ["places"],
    }),
    InfiniteScrollModule,
    NgxSpinnerModule
  ],
  exports: [
    RouterModule,

    CoolSocialLoginButtonsModule,

    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    SwiperModule,
    TranslateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule,
    LogoComponent,
    ImagePaiementComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    OurServicesComponent,
    OurAwardsComponent,
    //GetInTouchComponent,
    MenuItemComponent,
    //LoginFacebookComponent,
    SocialLoginComponent,
    AboutUmexiComponent,
    SearchAdressComponent,
    MenuItemsCarouselComponent,
    QuantityCounterComponent,
    RatingComponent,
    CommentsComponent,
    MenuItemsToolbarComponent,
    MenuItemHoverableComponent,
    OurChefsComponent,
    TimelineComponent,
    TestimonialsComponent,
    GetAppComponent,
    ReservationFormComponent,
    ReservationDialogComponent,
    DialogHeaderControlsComponent,
    ImageUploadComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SocialIconsComponent,
    TodayMenuComponent,
    LangComponent,
    OrderDetailsDialogComponent,
    HeaderVideoComponent,
    GetAppGalsenComponent,
    OurServicesGalsenComponent,
    TestimonialsGalsenComponent,
    MenuAdvantagesComponent,
    HeaderSearchComponent,
    HeaderSearchFormComponent,
    QuestionInsuranceComponent,
    EnQuelquesMotsComponent,
    LogoInsuranceComponent,
    MapSenegalComponent,
    SearchCarFilterComponent,
    SearchCarListComponent,
    InfiniteScrollModule,
    SearchAdressComponent,
    SearchCarComponent,
    SearchAdresseActuelComponent,
    SearchAdresseAccidentComponent,
    LatestLocationFormComponent,
    TransferTableSharedComponent,
    ReceptionTableSharedComponent,
    MenuItemHomeComponent,
    RentingListComponent,
    ChoosePaymentMethodComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: LOCALE_ID, useValue: "fr" },
  ],
})
export class SharedModule {}