import { DataSearchService } from 'src/app/services/data-search.service';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-header-search-form',
  templateUrl: './header-search-form.component.html',
  styleUrls: ['./header-search-form.component.scss']
})
export class HeaderSearchFormComponent implements OnInit {

  //Local Variable Defined
  address: any;
  geometry: any;

  options: Options;

  public minDateStart: Date;
  public minDateEnd: Date
  public currentDateStart: string;
  public currentDateEnd: string
  public showChoice: boolean;
  public showChoiceEnd: boolean;
  public errorAdress: boolean;

  public valueDatePickerStart: Date | null;
  public valueDatePickerEnd: Date | null;
  

  dateStart: any;
  dateEnd : any;
  todayWithPipe: any;
  periodStart: string | null;
  periodEnd: string | null;
  periodStartView: string | null;
  periodEndView: string | null;
  currentDateFormat: string;
  diffWeek: any;

  index: number;


  @Input('address') initAddress: string;
  @Output('emitter') emitter: EventEmitter<any> = new EventEmitter<any>();
  @Output('dateChange') dateChange: EventEmitter<number | null> = new EventEmitter<number | null>();


  constructor(private datePipe: DatePipe, 
              private dataSearchService: DataSearchService,
              private appService: AppService) { 

    this.showChoice = false;
    this.showChoiceEnd = false;
    this.errorAdress = false;

    //Permet l'affichage de l'element de choix
    this.index = 0;

    this.options = this.appService.getOptionPlacesMap();

  }


  ngOnInit(): void {
      this.initProperty();
  }

  private initProperty() {

    this.todayWithPipe = this.datePipe.transform(Date.now(), 'yyyy/MM/dd')
    this.valueDatePickerStart = this.dataSearchService.templateSearchCar.dateStart;
    this.valueDatePickerEnd = this.dataSearchService.templateSearchCar.dateEnd;
    this.dateStart = this.dataSearchService.templateSearchCar.dateStart;
    this.dateEnd = this.dataSearchService.templateSearchCar.dateEnd;
    if(this.dateStart != undefined) {

      this.minDateEnd = this.dateStart;
      this.minDateStart = this.dateStart;

    } else {

      this.minDateEnd = new Date();
      this.minDateStart = new Date();

    }

    this.periodStart = this.dataSearchService.templateSearchCar.periodStart;
    this.periodEnd = this.dataSearchService.templateSearchCar.periodEnd;

    this.periodStartView = this.dataSearchService.periodCondition(this.periodStart);
    this.periodEndView = this.dataSearchService.periodCondition(this.periodEnd);

  }


  public addressChange(address: any) {

    this.address = address.formatted_address;
    this.geometry = address.geometry;

    this.dataSearchService.address = this.address;
    this.dataSearchService.geometry = address.geometry;
    this.emitter.emit(address);

    // console.log(address)
    
  }


  public clickIconCalendar() {
    
      this.showChoice = false;

      this.showChoiceEnd = false;

      this.index = 0;

  }

  public dateStartChange(event: any) {
   
    this.dateStart = event.target.value;

    this.currentDateStart =  this.datePipe.transform(event.target.value, 'MM/dd/yyyy')!

    this.currentDateFormat = this.datePipe.transform(event.target.value, 'yyyy/MM/dd')!


    //On vérifier si la datePickerEnd est vide ou pas
    if(typeof this.valueDatePickerEnd != 'undefined') {

      this.valueDatePickerEnd = null

      this.periodStart = null;
      this.periodEnd = null;

      this.periodStartView = null;
      this.periodEndView = null;

    }

    //On vérifier si la datePickerStart est egalé à la date d'aujourd'hui
    if(this.currentDateFormat === this.todayWithPipe) {

      var currentHours = (new Date()).getHours();

      var currentMinutes = (new Date()).getMinutes();

      if(currentHours >= 11 || currentMinutes == 30) {
       
        this.minDateEnd.setDate(event.target.value.getDate() + 1);

        this.periodStart = 'Après 12h';

        this.periodStartView = 'Ap 12h'

        this.showChoice = false;

      } else {

        this.minDateEnd = event.target.value;

        this.showChoice = true;

      }

    } else {

      this.showChoice = true;

      this.minDateEnd = event.target.value;

    }

    // console.log(this.dateStart, event.target.value)

  }

  public dateEndChange(event: any) {

    this.dateEnd = event.target.value;

    this.periodEndView = null;

    this.currentDateEnd = this.datePipe.transform(event.target.value, 'MM/dd/yyyy')!

    var currentDate = this.datePipe.transform(event.target.value, 'yyyy/MM/dd')

    if(currentDate != this.currentDateFormat) {

      this.showChoiceEnd = true;

      let diff = this.dateEnd.getTime() - this.dateStart.getTime();
  
      this.diffWeek = (diff / (1000 * 3600 * 24));

    } else {

      this.periodEnd = "Après 12h";

      this.periodEndView = "Ap 12h"

      this.diffWeek = 1;

      this.dataSearchService.diffDate = this.diffWeek;

      this.dataSearchService.templateSearchCar = {"address": this.address, "geometry": this.geometry, "dateStart": this.dateStart,
                                  "dateEnd": this.dateEnd, "periodStart": this.periodStart!, "periodEnd": this.periodEnd!}

      this.dateChange.emit(this.diffWeek)

    }

    
  }

  public checkPeriodStart(element: string) {

    this.periodStart = element;

    if(element == 'Après 12h') {

      this.minDateEnd.setDate(this.dateStart.getDate() + 1);

      this.valueDatePickerEnd = null;
      
      this.dateStart = new Date(this.currentDateFormat);

      this.periodStartView = "Ap 12h"

    } else {

      this.periodStartView = "Av 12h";

    }

    // console.log(this.dateStart);

  }

  public checkPeriodEnd(element: string) {

    this.periodEnd = element;

    let valueEmit;

    if(this.periodStart != undefined) {

      if(this.periodStart == 'Après 12h') {

        valueEmit = this.diffWeek
  
      } else {
  
        if(this.periodStart == this.periodEnd) {
  
          valueEmit = this.diffWeek
  
        } else {
         
          this.diffWeek = this.diffWeek + 1;
  
          valueEmit = this.diffWeek;
  
        }

      }
      
      this.dataSearchService.diffDate = this.diffWeek;

      this.dataSearchService.templateSearchCar = {"address": this.address, "geometry": this.geometry, "dateStart": this.dateStart,
      "dateEnd": this.dateEnd, "periodStart": this.periodStart!, "periodEnd": this.periodEnd!}

  
      this.dateChange.emit(valueEmit);

    }

    if(element == "Après 12h") {

      this.periodEndView = 'Ap 12h';

    } else {

      this.periodEndView = 'Av 12h';

    }
  }


  @HostListener('document:click')
  clickedOut() {

    // console.log("j'ai clique en dehors du header", this.showChoice);

    if(this.showChoice || this.showChoiceEnd) {

      this.index ++

      if(this.index >= 2) {

        this.showChoice = false;

        this.showChoiceEnd = false;

        this.index = 0;

      }

    }
  }


  ngOnDestroy(): void {

    let templateSearchCar = {"address": this.address, "dateStart": this.dateStart, "geometry": null,
                                "dateEnd": this.dateEnd, "periodStart": this.periodStart!, "periodEnd": this.periodEnd!}

    this.dataSearchService.templateSearchCar = templateSearchCar;

    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }

}