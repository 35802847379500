import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'UserSearchs', pure: false })
// export class UserSearchPipe implements PipeTransform {
//   transform(value:any, args?:any) {
//     let searchText = new RegExp(args, 'ig');
//     if (value) {
//       return value.filter((user:any) => {
//         if (user.profile.name) {
//           return user.profile.name.search(searchText) !== -1;
//         }
//         else{
//           return user.username.search(searchText) !== -1;
//         }
//       });
//     }
//   }
// }
export class UserSearchs implements PipeTransform {
  transform(value:any, args?:any) {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter((car:any) => {
        if (car.mark_lib) {
          return car.mark_lib.search(searchText) !== -1;
        }
        else{
          return car.mod_lib.search(searchText) !== -1;
        }
      });
    }
  }
};