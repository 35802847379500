import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private spinner: NgxSpinnerService,
              private toastr: ToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      
      if(err.status === 401 || err.status === 0) {
        
        this.authService.logoutFront();

      }

      // const error = err.error.message || err.statusText;
      console.error(err)

      this.spinner.hide();
      
      // this.toastr.show(
      //   'Erreur survenue lors de l\'execution de la requête, veuillez réessayer plus tard',
      //   'Alert !',
      //   {
      //     timeOut: 5000,
      //     closeButton: true,
      //   } as IndividualConfig,
      //   'toast-' + 'error'
      // )
      
      return throwError(() => new Error('Opération impossible, veuillez vous reconnecter à nouveau.'));

    }));
  }
}
