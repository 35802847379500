 import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HostService {
  constructor() {}

  gethttps() {
  // return "http://localhost:8000/api/"
  //  return "https://apigalsenauto.jainli.com/api/";
  //return "https://apitest.locationsenegal.sn/api/";
  // return "https://api.locationsenegal.sn/api/";
  //return "https://apitest.rentbyauto.com/api/";
  // return "https://api.umexci.com/api/";
   return "https://api.VoitureEntreParticuliers.com/api/";
   

  }

  getHttpsImage() {
 // return "http://localhost:8000/storage/"
    // return "https://apigalsenauto.jainli.com/storage/";
    // return "https://d3ninrv2f0fdtz.cloudfront.net/storage/";
  //return "https://api.locationsenegal.sn/";
 // return "https://apitest.rentbyauto.com/storage/";
   // return "https://api.umexci.com/storage/";
   return "https://api.VoitureEntreParticuliers.com/storage/";
 

  }

  getHttpsEmail() {
   //  return "http://localhost:8000/"
  //  return "https://apigalsenauto.jainli.com/";
  //return "https://apitest.locationsenegal.sn/";
  // return "https://api.locationsenegal.sn/";
  //return "https://apitest.rentbyauto.com/";
 //return "https://api.umexci.com/";
  return "https://api.VoitureEntreParticuliers.com/";

  }
  getHttpsUrl() {
  //   return "http://localhost:4200/"
  //  return "https://apigalsenauto.jainli.com/";
  //return "https://apitest.locationsenegal.sn/";
  // return "https://api.locationsenegal.sn/";
  //return "https://apitest.rentbyauto.com/";
 // return "https://www.umexci.com/";
  return "https://www.VoitureEntreParticuliers.com/";

  }
  getBlogUrl() {
    return "https://blog.VoitureEntreParticuliers.com/";
    }
  getAideUrl() {
      return "https://aide.VoitureEntreParticuliers.com/";
      }
}
