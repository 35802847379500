import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { PaymentService } from '../services/payment.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentExtensionResolver implements Resolve<boolean> {
  constructor(private paymentService: PaymentService, private router: Router,
              private toastr: ToastrService, private spinner: NgxSpinnerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    
    this.spinner.show();

    // console.log(route.paramMap.get('paymentMethod'), route.paramMap.get("idRenting"),route.queryParamMap.get('token') )
    
    if(route.paramMap.get("id") == null  || route.paramMap.get('paymentMethod') == null) {

      this.toastr.show(
        'Oups, Une erreur est survenue lors de l\'execution',
        'Réesayez Réesayer',
        {
          timeOut: 5000,
          closeButton: true,
        } as IndividualConfig,
        'toast-' + 'error'
      )

      this.spinner.hide();

      this.router.navigate(['/']);

      return EMPTY;

    } else {

      if(route.paramMap.get("paymentMethod")! == "Pay" || route.paramMap.get("paymentMethod")! == "Pa") {

        if(route.queryParamMap.get('token') == null) {

          this.toastr.show(
            'Oups, Une erreur est survenue lors de l\'execution(Token)',
            'Réesayez Réesayer',
            {
              timeOut: 5000,
              closeButton: true,
            } as IndividualConfig,
            'toast-' + 'error'
          )
    
          this.spinner.hide();
    
          this.router.navigate(['/']);
    
          return EMPTY;
          
        } else {

          let obj = {
            token: route.queryParamMap.get('token'),
            id: route.paramMap.get("id")!,
            paymentMethod: 'Pay'
          }

          return this.paymentService.createExtension(obj);

        }

      } else if(route.paramMap.get("paymentMethod")! == "Wave") {

        let obj = {
          token: route.queryParamMap.get('token'),
          id: route.paramMap.get("id")!,
          paymentMethod: route.paramMap.get("paymentMethod")!
        }
        
        return this.paymentService.createExtension(obj);

      } else{
        
        // return this.paymentService.create(obj);

        this.toastr.show(
          'Oups, impossible d\'effectue cette transaction',
          'Réesayez Réesayer',
          {
            timeOut: 5000,
            closeButton: true,
          } as IndividualConfig,
          'toast-' + 'error'
        )
  
        this.spinner.hide();
  
        this.router.navigate(['/']);
  
        return EMPTY;


      }
      
    
      // return this.paymentService.create(obj);

    }
    
  }

  handleError(route: ActivatedRouteSnapshot, errorResponse: HttpErrorResponse) {
    
    switch (errorResponse.status) {
      case 404: {
        console.log(errorResponse)
        // this.router.navigate(['/**']);
        return Observable.arguments(null)
        // return Observable.of(null);
      }
      case 401: {
        const returnURL: string = '/' + route.url.map(segment => segment.path).join('/');
        this.router.navigate(['/login'], { queryParams: { returnURL: returnURL }});
        // return Observable.of(null);
        return Observable.arguments(null)
      }
      case 403: {
        this.router.navigate(['/unauthorized']);
        return Observable.arguments(null)
        // return Observable.of(null);
      }
      default: {

        this.toastr.show(
          'Oups, Une erreur est survenue lors de l\'execution',
          'Réesayez Réesayer',
          {
            timeOut: 5000,
            closeButton: true,
          } as IndividualConfig,
          'toast-' + 'error'
        )
        console.log(errorResponse);
        this.router.navigate(['/']);
        
        return Observable.arguments(null)
      }
    }
  }
}
