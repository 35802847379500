import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from "ts-cacheable"
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

    constructor(private getHost: HostService,
                private http: HttpClient) { }


    /**
     * URL de paiement avec les methodes paiements wave et paydunya pour une location
     * @param idRenting 
     * @param paymentMethod 
     */
    generateUrlPayment(idRenting: number, paymentMethod: string) {

      window.location.assign(this.getHost.gethttps()+'payment/'+idRenting+'/'+paymentMethod);

    }

    /**
    * URL de paiement avec les methodes paiements wave et paydunya pour une prolongation
    * @param idExtension 
    * @param paymentMethod 
    */
    generateUrlPaymentForExtension(idExtension: number, paymentMethod: string) {

      window.location.assign(this.getHost.gethttps()+'paymentExtension/'+idExtension+'/'+paymentMethod);

    }

    /**
     * Paiement avec un avoir
     * @param obj 
     */
    paymentWithAsset(obj: object) {

      return this.http.post(this.getHost.gethttps() + 'paymentWithAsset', obj);

    }


    @Cacheable()
    index() {
      
      return this.http.get(this.getHost.gethttps() + 'payment');

    }

    sumPerDay() {
      
      return this.http.get(this.getHost.gethttps() + 'sumPerDay');

    }
    
    sumPerMonth() {
      
      return this.http.get(this.getHost.gethttps() + 'sumPerMonth');

    }

    sumPerWeek() {
      
      return this.http.get(this.getHost.gethttps() + 'sumPerWeek');

    }
    

    index10OnYears() {

      return this.http.get(this.getHost.gethttps() + 'paymentOn10years');

    }

    create(objToken: Object) {

      return this.http.post(this.getHost.gethttps() + 'success_payment', objToken);

    }

    createExtension(objToken: Object) {

      return this.http.post(this.getHost.gethttps() + 'success_payment_extension', objToken);

    }


    allpaiment(val: any) {

      let obj = {
        idUser: val,
      };

      return this.http.post(this.getHost.gethttps() + "allpaiment", obj);

    }

    /**
     * Cette methode retourne tous les revenus d'un propriètaire de véhicule
     * 
     * @param obj 
     * @returns 
     */
    allIncome(obj: object) {
      
      return this.http.post(this.getHost.gethttps() + "allIncome", obj);
      
    }

    sumPaiement(val: any) {

      let obj = {
        idUser: val,
      };

      return this.http.post(this.getHost.gethttps() + "sumPaiement", obj);

    }
    
    /**
     * Cette methode retourné le montant total des revenus d'un propriètaire
     * 
     * @param obj 
     * @returns 
     */
    sumIncome(obj: object) {
      
      return this.http.post(this.getHost.gethttps() + "sumIncome", obj);
      
    }

    /**
     * Retourner le montant total se trouvant dans le compte de l'utilisateur
     * 
     * @param obj 
     * @returns 
     */
    currentAmount(obj: object) {
      
      return this.http.post(this.getHost.gethttps() + "currentAmount", obj);
      
    }


    /**
     * Retourner le montant encours pour le virement
     * 
     * @param obj 
     * @returns 
     */
    amountOutstanding(obj: object) {

      return this.http.post(this.getHost.gethttps() + "amountOutstanding", obj);

    }

    /**
     * Cette méthode permet de vérifier si un paiment a été enregistré pour une location
     * 
     * @param idRenting 
     * @returns 
    */
    @Cacheable()
    paymentOfRentingExist(idRenting: number) {

      return this.http.get(this.getHost.gethttps() + "paymentOfRentingExist/"+idRenting);

    }


    /**
     * Cette méthode permet de vérifier si un paiment a été enregistré pour une prolongation
     * 
     * @param idExtension 
     * @returns 
    */
    @Cacheable()
    paymentOfExtensionExist(idExtension: number) {

      return this.http.get(this.getHost.gethttps()+"paymentOfExtensionExist/"+idExtension);

    }

}
