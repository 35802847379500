<div fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
        <mat-card class="p-0 mat-elevation-z6 box">
            <div *ngIf="status == 0" fxLayout="column" fxLayoutAlign="center center" class="bg-success box-header">
                <mat-icon class="mat-icon-xlg">clear</mat-icon>
                <h1 class="error">Le Paiement a déjà été effectué Ou une erreur est survenue lors de l'enregistrement </h1>
            </div>
            <div *ngIf="status == 1" fxLayout="column" fxLayoutAlign="center center" class="bg-primary box-header">
                <mat-icon class="mat-icon-xlg">done_all</mat-icon>
                <h1 class="error">Paiement effectué</h1>
            </div>
            <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
                <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z8 box-content-inner">
                    <p class="box-text">{{message}}.</p>
                    <p *ngIf="status == 0" class="box-text">Réesayez Réesayer.</p>
                    <p *ngIf="status == 1" class="box-text">Paiement Valide.</p>
                </mat-card>
                <div class="box-footer">
                    <button mat-raised-button color="primary" class="mat-elevation-z8" type="button">
                        <mat-icon>home</mat-icon>
                    </button>
                    <button mat-raised-button color="primary" class="mat-elevation-z8" type="button">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>