import { DataSearchService } from 'src/app/services/data-search.service';
import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { HostService } from 'src/app/services/host.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-search-car-list',
  templateUrl: './search-car-list.component.html',
  styleUrls: ['./search-car-list.component.scss']
})

export class SearchCarListComponent implements OnInit {

    @Input('vehicles') vehicles: any[] = [];


    listVehicle : any[] = [];

    sum = 5;
    direction = "";

    oldValue: any[] = [];

    constructor(private router: Router,
                private dataSearchService: DataSearchService,
                public hostService: HostService,
                private el: ElementRef,
                public appService: AppService) { 
      
    }

    ngOnInit(): void {

      this.appendItems();

      this.oldValue = this.vehicles;
      
    }

    onScrollDown(ev: any) {
      
      // console.log("scrolled down!!", ev);

      this.sum += 20;
      this.appendItems();
      
    }

    onScrollUp(ev: any) {
      // console.log("scrolled up!", ev);
      this.sum += 20;
      this.prependItems();

      this.direction = "scroll up";
    }

    appendItems() {
      this.addItems("push");
    }

    prependItems() {
      this.addItems("unshift");
    }

    ngDoCheck(): void {

      if(this.oldValue.length != this.vehicles.length) {

        this.sum = 5;

        this.oldValue = this.vehicles;

        this.appendItems();

      } else {

        this.oldValue = this.vehicles;

        this.listVehicle = this.vehicles


      }

    }

    addItems(_method: string) {
      
      if(this.vehicles.length < this.sum) {

        let currentArray = [];

        for (let i = 0; i < this.vehicles.length; i++) {

          if(i < this.sum) {
  
            currentArray.push(this.vehicles[i]);
  
          } else {
  
            break;
  
          }
          
        }

        this.listVehicle = currentArray;

      } else {

        this.listVehicle = this.vehicles;

      }
      
  
      // for (let i = 0; i < this.sum; ++i) {
      //   if( _method === 'push'){
      //     this.listArray.push([i].join(""));
      //   }else if( _method === 'unshift'){
      //     this.listArray.unshift([i].join(""));
      //   }
      // }
    }

    clickCar(idCar: any) {
      
      this.dataSearchService.idCar = idCar;

      this.router.navigate(['location-car']);
      
    }

    @HostListener("error")
    onError() {
     
      this.el.nativeElement.style.display = "none";
    }
}
