import { Component, Input, OnInit, SimpleChange } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "src/app/app.models";
import { AppService } from "src/app/app.service";
import { DataSearchService } from "src/app/services/data-search.service";
import { VehiculeserviceService } from "src/app/services/vehiculeservice.service";
import { CartOverviewComponent } from "../cart-overview/cart-overview.component";
import { HostService } from "./../../services/host.service";
import { Subscription } from "rxjs";
import { AppSettings, Settings } from "src/app/app.settings";

@Component({
  selector: "app-menu-item-home",
  templateUrl: "./menu-item-home.component.html",
  styleUrls: ["./menu-item-home.component.scss"],
})
export class MenuItemHomeComponent implements OnInit {
  @Input() menuItem!: MenuItem;
  @Input() lazyLoad: boolean = false;
  public specialMenuItems: any = [];
  public page: any;
  public maxSize: number = 5;
  public autoHide: boolean = true;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: any;
  user: any;
  public column: number = 4;
  //Subscription
  vehicleSubscription: Subscription;

  @Input("mapHome") mapHome: boolean = true;
  @Input("mapLatestLocation") mapLatestLocation: boolean = false;
  @Input("geometry") geometry: any;
  @Input("markers") markers: any[] = [];

  public lat: number;
  public lng: number;
  public zoom: number;
  public settings: Settings;

  public markersHome: any;

  public icon: any;
  constructor(
    public hostService: HostService,
    private router: Router,
    public appService: AppService,
    public car: VehiculeserviceService,
    private dataSearchService: DataSearchService,
    private vehicleService: VehiculeserviceService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(sessionStorage.getItem("user")!);
    this.getSpecialMenuItemCar();
    this.initProperty();
  }
  initProperty() {
    this.lat = 14.497401;
    this.lng = -14.452362;
    this.zoom = 9;

    this.icon = this.hostService.getHttpsImage() + "icon_marker.png";

    //Ici nous recuperons les markers pour la carte se trouvant sur la première page
    this.vehicleSubscription = this.car.getallcar().subscribe((marks: any) => {
      console.log(marks);
      this.markersHome = marks;

      // console.log(this.markersHome, marks)
    });
  }
  public onPageChanged(event: any) {
    this.page = event;

    window.scrollTo(0, 0);
    // if(this.settings.fixedHeader){
    //     document.getElementById('main-content').scrollTop = 0;
    // }
    // else{
    //     document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
    // }
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.viewColChanged) {
      this.getColumnCount(changes.viewColChanged.currentValue);
    }
  }
  public getSpecialMenuItemCar() {
    this.car.getallcar().subscribe((data: any) => {
      console.log(data);
      this.specialMenuItems = data;
    });
  }

  public getColumnCount(value: number) {
    if (value == 25) {
      this.column = 4;
    } else if (value == 33.3) {
      this.column = 3;
    } else if (value == 50) {
      this.column = 2;
    } else {
      this.column = 1;
    }
  }

  public addToCart() {
    this.appService.addToCart(this.menuItem, CartOverviewComponent);
  }

  public onCart() {
    if (
      this.appService.Data.cartList.find((item) => item.id == this.menuItem.id)
    ) {
      return true;
    }
    return false;
  }

  public addToFavorites() {
    this.appService.addToFavorites(this.menuItem);
  }

  public onFavorites() {
    if (
      this.appService.Data.favorites.find((item) => item.id == this.menuItem.id)
    ) {
      return true;
    }
    return false;
  }
  clickCar(event: any) {
    console.log(event);
    this.dataSearchService.idCar = event;

    let currentMarker = this.markersHome.find(
      (element: any) => element.id == event
    );
    console.log(this.markersHome);

    this.dataSearchService.address = currentMarker.vehicle_adress;

    this.dataSearchService.lat = currentMarker.lat;

    this.dataSearchService.lng = currentMarker.lng;

    this.dataSearchService.isRentingMap = true;
    this.dataSearchService.idCar = event;

    this.router.navigate(["location-car"]);
  }
}