import { MapsAPILoader } from '@agm/core';
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeolocalisationService {

  
  title: string = 'AGM project';
  lat: number;
  long: number;
  zoom: number;
  adresse: string;
  private geoCoder:any;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) { }

  getCurrentLocalisationUser()
  {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.lat,this.long);
      });
    }
  }
  
  getAddress(latitude:number, longitude:number) {
    var adresse:string='';
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results:any, status:any) => {
      
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          let currentPosition={
            lat:latitude,
            long:longitude,
            address:results[0].formatted_address
          }
          localStorage.setItem('myCurrentPosition', JSON.stringify(currentPosition));
        } else {
          console.log('No results found')
        }
      } else {
        console.log('Geocoder failed due to: ' + status) 
      }
    
    });
  }
}
