<div fxLayout="row" fxLayoutAlign="space-between center" class="p-2">
    <div fxLayout="row">
        <mat-button-toggle-group #controls="matButtonToggleGroup" appearance="legacy">            

            <mat-button-toggle value="search">
                <mat-icon>search</mat-icon>
            </mat-button-toggle>           
        </mat-button-toggle-group>            
    </div>
    <form class="user-search" [class.show]="controls.value == 'search'">
        <mat-form-field class="user-search-input">
            <input matInput placeholder="Search location..." (keyup)="applyFilter($event)" name="search">
        </mat-form-field>
    </form>
</div>
<mat-card class="m-2 p-0">
    <div class="table-wrapper"> 
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
        
            <!-- Number Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                <td mat-cell *matCellDef="let renting; let i = index">#{{i+1}} </td>
            </ng-container>  

            <!-- Period of renting Column -->
            <ng-container matColumnDef="period_renting">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date D - Date F </th>
                <td mat-cell *matCellDef="let renting">
                    <span>Départ : 
                        <span class="period-location">{{renting.renting_date_departure}}</span>   
                        {{renting.renting_hour_departure}}<br>
                    </span> 
                       
                    <span>Retour : 
                        <span class="period-location">{{renting.renting_date_return}}</span>   
                        {{renting.renting_hour_return}}<br>
                    </span> 
                </td>
            </ng-container>  
            
            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant </th>
                <td mat-cell *matCellDef="let renting">{{renting.renting_price_location}} {{currency}}</td>
            </ng-container>  

             <!-- Amount Owner Column -->
             <ng-container matColumnDef="amount_owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant a reversé </th>
                <td mat-cell *matCellDef="let renting">{{renting.price_to_be_paid}} {{currency}}</td>
            </ng-container>  

            <!-- Car Column -->
            <ng-container matColumnDef="car">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Véhicule </th>
                <td mat-cell *matCellDef="let renting">{{renting.mark_lib}} {{renting.mod_lib}}</td>
            </ng-container> 

            <!-- status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> status </th>
                <td mat-cell *matCellDef="let renting">
                    <span *ngIf="status == 'progress'">
                        <span *ngIf="renting.renting_date_departure <= currentDate! ; else elseBlock">En Cours</span>
                        <ng-template #elseBlock>Payée</ng-template> 
                    </span>
                    <span *ngIf="status == 'end'">Terminée</span>
                    <span *ngIf="status == 'cancel' || status == 'pending'">
                        <span *ngIf="renting.renting_status == 'REJETER'">Rejetée</span>
                        <span *ngIf="renting.renting_status == 'ANNULER'">Annulée</span>
                        <span *ngIf="renting.renting_status == 'EN_ATTENTE'">En Attente de Validation</span>
                        <span *ngIf="renting.renting_status == 'VALIDER'">En Attente de Paiement</span>
                    </span>
                </td>
            </ng-container> 

            <!-- Logder Column -->
            <ng-container matColumnDef="logder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Locataire </th>
                <td mat-cell *matCellDef="let renting">{{renting.logder_name}} {{renting.logder_surname}}</td>
            </ng-container>  

            <!-- Owner Column -->
            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Propriètaire </th>
                <td mat-cell *matCellDef="let renting">{{renting.user_name}} {{renting.user_surname}}</td>
            </ng-container>  

            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let renting">{{ renting.created_at | date:"dd MMMM, yyyy 'à' HH:mm" }} </td>
            </ng-container> 
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let renting; columns: displayedColumns;"></tr>
        </table> 
        <p *ngIf="dataSource && dataSource.data.length === 0" class="py-5 mb-0 text-center">Aucune Location</p> 
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
</mat-card>