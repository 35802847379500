import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { Transfer } from 'src/app/app.models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from 'src/app/services/payment.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-reception-table-shared',
  templateUrl: './reception-table-shared.component.html',
  styleUrls: ['./reception-table-shared.component.scss']
})
export class ReceptionTableSharedComponent implements OnInit, OnDestroy {

  //Subscription

  paymentSubscription: Subscription;
  public sum: number;

  transfers: any;
  sumPerDay: number;
  sumPerMonth: number;
  sumPerWeek: number;
  gainPerDay: number;
  gainPerMonth: number;
  gainPerWeek : number;

  // displayedColumns: string[] = ['id', 'amount', 'type', 'status', 'user', 'phone', 'date', 'action'];
  // dataSource!: MatTableDataSource<Transfer>;
  // @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  // @ViewChild(MatSort, {static: true}) sort!: MatSort;

  user: any;
  gain: number;

  currency: string;
  
  constructor(private paymentService: PaymentService, public router: Router,
              private appService: AppService) { }

  ngOnInit(): void {
    
    this.initProperty();

  }

  private initProperty() {

   this.currency = this.appService.getCurrency();
   
    this.paymentService.sumPerDay().subscribe((response: any) => {
      //console.log(response);
       this.sumPerDay = response.sumPerDay;
      this.gainPerDay = (response.sumPerDay * 10)/100;

    })

    this.paymentService.sumPerMonth().subscribe((response: any) => {
      //console.log(response);
      this.sumPerMonth = response.sumPerMonth;
      this.gainPerMonth = (response.sumPerMonth * 10)/100;

    })

    this.paymentService.sumPerWeek().subscribe((response: any) => {
     // console.log(response);
      this.sumPerWeek = response.sumPerWeek;
      this.gainPerWeek = (response.sumPerWeek * 10)/100;

    })

  }







 

  ngOnDestroy(): void {
    
    //this.paymentSubscription.unsubscribe();

  }

   

}