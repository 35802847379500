import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { Transfer } from 'src/app/app.models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TransferService } from 'src/app/services/transfer.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ChoosePaymentMethodComponent } from '../choose-payment-method/choose-payment-method.component';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-transfer-table-shared',
  templateUrl: './transfer-table-shared.component.html',
  styleUrls: ['./transfer-table-shared.component.scss']
})
export class TransferTableSharedComponent implements OnInit, OnDestroy {

  //Subscription
  transferSubscription: Subscription
  changeStatusSubscription: Subscription
  PaymentSubscription: Subscription

  //Boolean
  transferGetted: boolean;
  statusChanged: boolean;
  paymentInited: boolean;

  public transfers: any;
  sumTransfer: number;
  sumRenting: number;
  sumAsset: number;
  currentAmount: number;
  waveBalance: number;
  orangeBalance: number;


  displayedColumns: string[] = ['id', 'amount', 'type', 'status', 'user', 'phone', 'date', 'action'];
  dataSource!: MatTableDataSource<Transfer>;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  user: any;
  gain: number;

  public currency: string;
  
  constructor(public transferService: TransferService, public router: Router,
              private toastr: ToastrService, private datePipe: DatePipe,
              private spinner: NgxSpinnerService, private dialog: MatDialog,
              private appService: AppService) { }

  ngOnInit(): void {
    
    this.initProperty();

  }

  private initProperty() {

    this.currency = this.appService.getCurrency();

    this.user = JSON.parse(localStorage.getItem("user")!)

    // console.log(this.user);

    if(this.user == undefined) {
     
      this.transferGetted = false;

      this.router.navigate(['login']);

    } else { 
      
      this.getTransfers();
      
    }

    this.statusChanged = false;
    this.paymentInited = false;

  }

  private getTransfers() {

    this.transferSubscription = this.transferService.index().subscribe((data: any) => {
      console.log(data);
      this.transfers = data.transfers;
      this.sumTransfer = data.sumTransfer;
      this.sumRenting = data.sumRenting;
      this.sumAsset = data.sumAsset;
      this.currentAmount = data.currentAmount;
      this.gain = (data.sumRenting * 10)/100;
      this.waveBalance = data.waveBalance;
      this.orangeBalance = data.orangeBalance;
      
      this.dataSource = new MatTableDataSource(data.transfers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      (this.dataSource.sortingDataAccessor as any) = (data:any, sortHeaderId: string) => {
        return this.getPropertyByPath(data, sortHeaderId);
      };

    })

  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o:any, i) => o[i], obj);
  }


  /**
   * Cette methode permet de changer le status d'une demande de virment
   * @param idTransfer 
   * @param value 
   */
  public changeStatus(idTransfer: number, value: string) {

    let currentObject = this.transfers.find((renting: any) => renting.id == idTransfer);

    if(currentObject?.transfer_status != value) {

      this.spinner.show();

      let obj = {
        'id': idTransfer,
        'status': value
      };
  
      this.changeStatusSubscription = this.transferService.changeStatusTransfer(obj).subscribe((response: any) => {
  
        this.getTransfers();
  
        this.spinner.hide();

        this.toastr.show(
          'Le status de la demande de virement du '+this.datePipe.transform(currentObject?.created_at, 'yyyy/MM/dd')+
          ' concernant le client '+currentObject?.user_name+' '+currentObject?.user_surname+' a été modifié',
          'Status Changé',
          {
            timeOut: 5000,
            closeButton: true,
          } as IndividualConfig,
          'toast-' + 'success'
        )
  
      })

    } else {

      this.toastr.show(
        'Impossible d\'effectuer cette action',
        'Erreur Erreur',
        {
          timeOut: 5000,
          closeButton: true,
        } as IndividualConfig,
        'toast-' + 'error'
      )

    }

  }


  /**
   * On selectionner le moyen de paiement avant de proceder au paiement
  */
  public choosePaymentMethod(transfer: any) {
    
    if(transfer.numero_compte == "" || transfer.numero_compte == null || transfer.numero_compte == undefined) {

      this.toastr.show(
        'L\'utilisateur '+transfer.user_name+' n\'a pas enregistré son numéro de compte.',
        'Erreur',
        {
          timeOut: 5000,
          closeButton: true,
        } as IndividualConfig,
        'toast-' + 'error'
      )

    } else {

      const dialogRef = this.dialog.open(ChoosePaymentMethodComponent, {
        data: {
          id: transfer.id,
          key: 'transfer',
          transfer: transfer
        },
  
        disableClose: true
  
      });
  
      dialogRef.afterClosed().subscribe((result) => {
  
        if (result === 1) {
          
          this.getTransfers();
          
        }
  
      });

    }
    

  }
  
/*
  public initialeTransfer(id: number) {

    // console.log(id);

    let currentObject = this.transfers?.find((transfer: any) => transfer.id == id);

    if(currentObject == null || currentObject == undefined) {

      this.toastr.show(
        'Impossible d\'effectuer cette action',
        'Erreur',
        {
          timeOut: 5000,
          closeButton: true,
        } as IndividualConfig,
        'toast-' + 'error'
      )

    } else {

      this.spinner.show();

      let obj = {
        'id': id,
        'amount': currentObject.transfer_amount,
        // 'phone': currentObject.user_phone
        'phone': '+221775168918'
      }

      this.PaymentSubscription = this.transferService.paymentOfTransfer(obj).subscribe((response: any) => {
        // console.log(response)
        response = response['res'];

        this.spinner.hide();

        if(response != null) {

          if(response['status']) {

            this.toastr.show(
              response['message'],
              'Success Success',
              {
                timeOut: 5000,
                closeButton: true,
              } as IndividualConfig,
              'toast-' + 'success'
            )
  
            this.getTransfers();
  
          } else {
  
            this.toastr.show(
              response['message'],
              'Erreur',
              {
                timeOut: 5000,
                closeButton: true,
              } as IndividualConfig,
              'toast-' + 'error'
            )
  
          }

        } else {

          this.toastr.show(
            'Erreur survenue lors de l\'execution de la requête',
            'Erreur',
            {
              timeOut: 5000,
              closeButton: true,
            } as IndividualConfig,
            'toast-' + 'error'
          )
        }
       

      })

    }

  }
*/
  ngOnDestroy(): void {
    
    if(this.transferGetted) {

      this.transferSubscription.unsubscribe();

    }

    if(this.statusChanged) {

      this.changeStatusSubscription.unsubscribe();

    }

    if(this.paymentInited) {

      this.PaymentSubscription.unsubscribe();

    }

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }  

}