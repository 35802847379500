// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environmentdev = {
    production: false,
    firebase : {
      // apiKey: "AIzaSyC8D9mBDIAs-5BX7WRzwVXw-Ni511lYovE",
      // authDomain: "hotel-management-3390d.firebaseapp.com",
      // projectId: "galsen-auto-359609",
      // storageBucket: "hotel-management-3390d.appspot.com",
      // messagingSenderId: "1010076842792",
      // appId: "1:1010076842792:web:a1f076a7eb815a0aae6e1d"

  // apiKey: "AIzaSyC8D9mBDIAs-5BX7WRzwVXw-Ni511lYovE",
  // authDomain: "galsen-auto-359609.firebaseapp.com",
  // projectId: "galsen-auto-359609",
  // storageBucket: "galsen-auto-359609.appspot.com",
  // messagingSenderId: "400249903992",
  // appId: "1:400249903992:web:4f871bb77ba92db0c5c68d",
  // measurementId: "G-30BVW59JRJ"
  apiKey: "AIzaSyBjj0LEsQS4RBUl8DvhztSoeeOL372yDEA",
  authDomain: "locationsenegal-sn.firebaseapp.com",
  projectId: "locationsenegal-sn",
  storageBucket: "locationsenegal-sn.appspot.com",
  messagingSenderId: "189704678535",
  appId: "1:189704678535:web:7addd0825838465f429c22",
  measurementId: "G-FF03W1XST9"
    }
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  
  