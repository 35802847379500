import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NumeroCompte } from '../app.models';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class NumeroCompteService {

  constructor(private getHost: HostService,
              private http: HttpClient) { }

  create(numeroCompte: NumeroCompte) {
    
    return this.http.post(this.getHost.gethttps() + 'numeroCompte', numeroCompte);

  }

  /**
   * Retourner les informations concernant le compte de l'utilisateur
   * @param idUser 
   * @returns 
   */
  numeroCompteOfUser(idUser: number) {

    return this.http.get(this.getHost.gethttps() + "numeroCompteOfUser/"+idUser);

  }
}
