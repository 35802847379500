<div *ngIf="!mapHome && !mapLatestLocation" class="contact-map">
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true" [fullscreenControl]="true" [styles]="(settings.theme == 'orange-dark') ? mapStyles : null">
        <agm-marker class="dBRNZm" *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng" [opacity]="marker.alpha" [label]="marker.labelOption" [markerDraggable]="false" [iconUrl]="icon" (markerClick)="selectMarker($event)"></agm-marker>
    </agm-map>
</div>

<div *ngIf="!mapHome && mapLatestLocation" class="latest-location-map">
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true" [fullscreenControl]="true" [styles]="(settings.theme == 'orange-dark') ? mapStyles : null">
        <agm-marker class="dBRNZm" *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng" [opacity]="marker.alpha" [label]="marker.labelOption" [markerDraggable]="false" [iconUrl]="icon" (markerClick)="selectMarker($event)"></agm-marker>
    </agm-map>
</div>

<div *ngIf="mapHome" class="contact-map-home">
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true" [fullscreenControl]="true" [styles]="(settings.theme == 'orange-dark') ? mapStyles : null">
        <agm-marker class="dBRNZm" *ngFor="let marker of markersHome" [latitude]="marker.lat" [longitude]="marker.lng" [opacity]="marker.alpha" [label]="marker.labelOption" [markerDraggable]="false" [iconUrl]="icon" (markerClick)="selectMarkerMapHome($event)"></agm-marker>
    </agm-map>
</div>