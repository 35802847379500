import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TemplateSearchCar } from "src/app/app.models";
import { DataSearchService } from "./../../services/data-search.service";
import { DatePipe } from "@angular/common";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { Output, EventEmitter } from "@angular/core";
import { AppService } from "src/app/app.service";

@Component({
  selector: "app-search-adresse-actuel",
  templateUrl: "./search-adresse-actuel.component.html",
  styleUrls: ["./search-adresse-actuel.component.scss"],
})
export class SearchAdresseActuelComponent implements OnInit {
  //Local Variable Defined
  @Output() newItemEvent = new EventEmitter<any>();
  address: string;
  geometry: object;

  options: Options;

  constructor(
    private appService: AppService
  ) {
    this.options =  this.appService.getOptionPlacesMap();
  }

  ngOnInit(): void {
    
  }

  public addressChange(address: any) {
    this.address = address.formatted_address;
    let obj = {
      adress: this.address,
      latitude: address.geometry.location.lat(),
      lonitude: address.geometry.location.lng(),
    };

    this.geometry = address.geometry.location;
    console.log(obj);
    this.newItemEvent.emit(obj);
  }
}