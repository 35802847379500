import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {

  email : string = '';
  family_name : string = '';
  given_name : string = '';
  id : string = '';
 password : string = '';
  idToken : string = '';
 
  constructor(private auth : AuthService) { }

  ngOnInit(): void {
  }

  signInWithGoogle() {
   
    this.auth.googleSignIn();
  }

  signInWithFacebook() {
   
    this.auth.facebookSignIn();
  }
 
   signInWithApple() {
   
     this.auth.appleSignIn();
   }

}
