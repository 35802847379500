import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HostService } from './host.service';


@Injectable({
  providedIn: 'root'
})
export class NotationCarService {

  constructor(private getHost: HostService, private http: HttpClient) { }


  create(data: any) {

    return this.http.post(this.getHost.gethttps() + "noteCar", data);

  }

   /**
   * Cette methode permet de vérifier si une note a déjà été emissé pour 
   * la location en cours.
   * 
   * @param idRenting 
   */
   notationExist(obj: object) {

    return this.http.post(this.getHost.gethttps() + "notationExist", obj);

  }


  /**
   * Retour tous les notes attribues à un véhicule
   * 
   * @param idVehicle 
   * @returns 
   */
  vehicleRating(idVehicle: number) {

    return this.http.get(this.getHost.gethttps()+ "vehicleRating"+'/'+idVehicle);

  }

  /**
   * Retour tous les notes attribues à un utilisateur
   * 
   * @param idUser
   * @returns 
  */
  userRating(idUser: number) {

    return this.http.get(this.getHost.gethttps()+ "userRating"+'/'+idUser);
  
  }
  

}
