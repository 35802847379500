<div class="pg-home-assurance">
    <div class="inside-wide with-padding-rwd">
        <div class="bloc">
            <svg color="#0000FF" class="svg-icon svg-assurance" viewBox="0 0 1024 1024" style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 40px; height: 40px;">
                <path d="M342.551 252.635c0 38.537-27.562 69.778-61.561 69.778s-61.561-31.241-61.561-69.778c0-38.537 27.562-69.778 61.561-69.778s61.561 31.241 61.561 69.778zM732.438 322.413c33.997 0 61.561-31.241 61.561-69.778s-27.564-69.778-61.561-69.778c-34 0-61.564 31.241-61.564 69.778s27.564 69.778 61.564 69.778zM562.626 242.538c7.27-18.845-2.114-40.014-20.959-47.282s-40.013 2.116-47.283 20.961l-100.93 261.677-0.369 1.203c-4.579 14.837-8.543 39.914 0.011 63.813 4.601 12.855 13.071 25.849 27.136 35.313 13.912 9.362 30.438 13.356 47.989 13.356h120.572c20.198 0 36.571-16.373 36.571-36.571s-16.373-36.571-36.571-36.571h-120.572c-3.365 0-5.372-0.377-6.418-0.673-0.358-1.273-0.713-3.573-0.567-6.938 0.146-3.321 0.742-6.597 1.452-9.176l99.939-259.113zM460.862 517.42l0.084 0.044c-0.059-0.026-0.084-0.040-0.084-0.044zM789.53 648.042c0-20.198-16.373-36.571-36.571-36.571-20.195 0-36.571 16.373-36.571 36.571 0 71.943-28.372 108.237-59.677 127.678-33.905 21.061-76.343 25.289-104.836 22.056-20.070-2.275-38.184 12.153-40.459 32.223-2.275 20.067 12.153 38.181 32.219 40.455 39.91 4.524 100.078-0.556 151.669-32.6 54.199-33.668 94.226-94.288 94.226-189.813z">
                </path>
            </svg>
            <p class="txt ml3 text-galsen">Conducteurs supplémentaires et tous équipements gratuits</p>
        </div>
        <div class="bloc">
            <svg color="#0000FF" class="svg-icon svg-assurance" viewBox="0 0 1024 1024" style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 40px; height: 40px;">
                <path d="M527.696 94.964c-4.974-2.368-10.35-3.538-15.711-3.536-5.358-0.002-10.734 1.168-15.707 3.536-3.174 1.507-6.17 3.494-8.869 5.952-92.5 81.676-213.137 124.193-336.417 118.551-16.829-0.77-32.007 10.056-36.759 26.219-20.736 70.538-27.080 144.524-18.657 217.561 8.423 73.040 31.442 143.642 67.69 207.605 36.247 63.967 84.983 119.995 143.312 164.754 58.329 44.756 125.060 77.334 196.228 95.795 6.020 1.562 12.343 1.562 18.363 0 71.168-18.461 137.9-51.039 196.228-95.795 58.328-44.76 107.063-100.787 143.312-164.754 36.246-63.963 59.264-134.565 67.69-207.605 8.422-73.037 2.077-147.023-18.659-217.561-4.751-16.163-19.928-26.989-36.758-26.219-123.282 5.642-243.917-36.875-336.417-118.551-2.699-2.458-5.694-4.445-8.869-5.952zM177.377 293.087c121.468-1.026 239.183-42.363 334.609-117.469 95.429 75.106 213.146 116.443 334.61 117.469 12.266 52.94 15.382 107.662 9.139 161.781-7.3 63.301-27.249 124.485-58.664 179.924s-73.651 103.995-124.204 142.786c-48.033 36.86-102.645 64.187-160.881 80.541-58.236-16.355-112.845-43.681-160.879-80.541-50.552-38.791-92.789-87.347-124.204-142.786s-51.365-116.623-58.665-179.924c-6.242-54.118-3.127-108.841 9.139-161.781zM665.852 452.528c14.281-14.285 14.281-37.438 0-51.719-14.285-14.285-37.438-14.285-51.723 0l-144.805 144.805-59.472-59.472c-14.285-14.281-37.438-14.281-51.721 0s-14.282 37.438 0 51.719l85.334 85.332c14.281 14.285 37.438 14.285 51.719 0l170.668-170.664z">
                </path>
            </svg>
            <p class="txt ml3 text-galsen">Assurance multirisque et assistance 24h/7j.</p>
        </div>
        <div class="bloc">
            <svg color="#0000FF" class="svg-icon svg-assurance" viewBox="0 0 1024 1024" style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 40px; height: 40px;"><path d="M373.731 182.857h262.777c65.207 0 123.736 40.002 147.427 100.754v0l44.153 113.247 60.745-12.149c19.803-3.961 39.073 8.883 43.034 28.69 3.961 19.803-8.883 39.073-28.69 43.034l-8.766 1.752c26.478 28.288 42.693 66.308 42.693 108.116v91.246c0 34.768-18.22 65.284-45.63 82.52v59.995c0 36.999-29.989 66.988-66.988 66.988h-91.246c-36.996 0-66.988-29.989-66.988-66.988v-45.111h-322.246v45.111c0 36.999-29.991 66.988-66.987 66.988h-91.248c-36.997 0-66.988-29.989-66.988-66.988v-59.992c-27.415-17.232-45.636-47.751-45.636-82.523v-91.246c0-41.432 15.923-79.144 41.984-107.352l-12.581-2.516c-19.806-3.961-32.65-23.23-28.689-43.034 3.961-19.807 23.228-32.651 43.034-28.69l64.929 12.987 44.482-114.085 0-0c23.688-60.754 82.219-100.754 147.426-100.754zM753.953 408.064l-38.166-97.882c-12.738-32.67-44.215-54.182-79.28-54.182h-262.777c-35.067 0-66.542 21.51-79.28 54.181v0l-38.166 97.883h497.668zM739.394 793.907h78.936v-38.956h-78.936v38.956zM270.862 754.951h-78.937v38.956h78.937v-38.956zM164.722 681.103c1.867 0.461 3.819 0.706 5.828 0.706h669.149c13.396 0 24.261-10.862 24.261-24.261v-91.246c0-46.998-38.1-85.094-85.094-85.094h-547.485c-46.995 0-85.092 38.096-85.092 85.094v91.246c0 11.224 7.62 20.667 17.969 23.439l0.464 0.117zM216.181 587.151c0-25.198 20.426-45.623 45.624-45.623s45.624 20.425 45.624 45.623c0 25.198-20.426 45.627-45.624 45.627s-45.624-20.429-45.624-45.627zM748.445 541.528c25.198 0 45.623 20.425 45.623 45.623s-20.425 45.627-45.623 45.627c-25.198 0-45.623-20.429-45.623-45.627s20.425-45.623 45.623-45.623z">
            </path>
            </svg>
            <p class="txt ml3 text-galsen">30 000 voitures et utilitaires à louer partout au Sénégal</p>
        </div>
    </div>
</div>