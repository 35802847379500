import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-detail-location',
  templateUrl: './detail-location.component.html',
  styleUrls: ['./detail-location.component.scss']
})
export class DetailLocationComponent implements OnInit {

  public dataPrice: any;
  public currency: string;

  constructor(public dialogRef: MatDialogRef<DetailLocationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private appService: AppService) { }

  ngOnInit(): void {

    this.dataPrice = this.data;

    this.currency = this.appService.getCurrency();

    // console.log(this.data);

  }

}
