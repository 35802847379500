<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">call</mat-icon>
  <span class="px-1">(+221) 77 516 89 18</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <span class="px-1">Arafat 2 villa N°794, Rufisque, Sénégal</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-sm></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-sm>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">mail</mat-icon>
  <span class="px-1">contact@Voitureentreparticuliers.com</span>
</span>
