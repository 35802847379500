import { Router } from '@angular/router';
import { TemplateSearchCar } from 'src/app/app.models';
import { DataSearchService } from './../../services/data-search.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, HostListener, Input } from '@angular/core';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { AppService } from 'src/app/app.service';



@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit, OnDestroy {

  //Local Variable Defined
  address: string;
  geometry: object;
  @Input('modelCar') modelCar:string; 
  options: Options;

  public minDateStart: Date;
  public minDateEnd: Date
  public currentDateStart: Date;
  public showChoice: boolean;
  public showChoiceEnd: boolean;
  public errorAdress: boolean;
  public valueDatePickerEnd?: Date;

  dateStart: Date;
  dateEnd : Date;
  todayWithPipe: any;
  periodStart?: string;
  periodEnd?: string;
  periodStartView: string | null;
  periodEndView: string | null;
  currentDateFormat: string;
  templateSearchCar : TemplateSearchCar; 

  index: number;

  constructor(private datePipe: DatePipe, 
              private dataSearchService: DataSearchService,
              private router: Router,
              private appService: AppService) { 

    this.minDateStart = new Date();
    this.minDateEnd = new Date();
    this.showChoice = false;
    this.showChoiceEnd = false;
    this.errorAdress = false;

    //Permet l'affichage de l'element de choix
    this.index = 0;
    
    this.options = this.appService.getOptionPlacesMap();

  }


  ngOnInit(): void {
    
    this.todayWithPipe = this.datePipe.transform(this.minDateStart, 'yyyy/MM/dd');
  }
 

  public addressChange(address: any) {

    this.address = address.formatted_address;

    this.geometry = address.geometry;

  }

  public clickIconCalendar() {
    
      this.showChoice = false;

      this.showChoiceEnd = false;

      this.index = 0;

  }

  public dateStartChange(event: any) {
 
    this.dateStart = event.target.value;

    this.currentDateStart = event.target.value

    this.currentDateFormat = this.datePipe.transform(event.target.value, 'yyyy/MM/dd')!

    //On vérifier si la datePickerEnd est vide ou pas
    if(typeof this.valueDatePickerEnd != 'undefined') {

      this.valueDatePickerEnd = undefined
      this.periodStartView = null;
      this.periodEndView = null;

    }

    //On vérifier si la datePickerStart est egalé à la date d'aujourd'hui
    if(this.currentDateFormat === this.todayWithPipe) {

      var currentHours = (new Date()).getHours();

      var currentMinutes = (new Date()).getMinutes();

      if(currentHours >= 11 || currentMinutes == 30) {
        
        this.minDateEnd.setDate(event.target.value.getDate() + 1);

        this.periodStart = 'Après 12h';

        this.periodStartView = 'Ap 12h'

        this.showChoice = false;

      } else {

        this.minDateEnd = event.target.value;

        this.showChoice = true;

      }

    } else {

      this.showChoice = true;

      this.minDateEnd = event.target.value;

    }

  }


  public dateEndChange(event: any) {

    this.dateEnd = event.target.value;

    var currentDate = this.datePipe.transform(event.target.value, 'yyyy/MM/dd')
    
    if(currentDate != this.currentDateFormat) {

      this.showChoiceEnd = true;
      this.periodEndView = null;

    } else {

      this.periodEnd = "Après 12h";

      this.periodEndView = "Ap 12h"

    }

  }


  public checkPeriodStart(element: string) {

    this.currentDateStart = this.dateStart;

    this.periodStart = element;
    
    if(element == 'Après 12h') {

      this.minDateEnd.setDate(this.currentDateStart.getDate() + 1);

      this.valueDatePickerEnd = undefined
      
      this.dateStart = new Date(this.currentDateFormat);

      this.periodStartView = 'Ap 12h'

    } else {

      this.periodStartView = 'Av 12h'

    }

  }



  public checkPeriodEnd(element: string) {

    this.periodEnd = element;

    if(element == 'Après 12h') {

      this.periodEndView = 'Ap 12h'

    } else {

      this.periodEndView = 'Av 12h'

    }


  }
  

  public search() {

    // console.log(this.address, this.dateStart, this.dateEnd, this.periodStart, this.periodEnd);

    if(typeof this.address == 'undefined') {

        this.errorAdress = true;

    } else {

      this.errorAdress = false;

      this.templateSearchCar = {"address": this.address, "geometry": this.geometry, "dateStart": this.dateStart,
                                "dateEnd": this.dateEnd, "periodStart": this.periodStart!, "periodEnd": this.periodEnd!}

      let currentDiffWeekOne;

      //On calculer la difference de jours entre les deux dates
      if(this.dateStart == undefined && this.dateEnd == undefined) {

        currentDiffWeekOne = null;

      } else if(this.dateStart != undefined && this.dateEnd == undefined) {

        currentDiffWeekOne = 1;

      } else {

        if(this.datePipe.transform(this.dateStart, 'MM/dd/yyyy') == this.datePipe.transform(this.dateEnd, 'MM/dd/yyyy')) {
          currentDiffWeekOne = 1;
        } else {
          let diff = this.dateEnd.getTime() - this.dateStart.getTime();
          currentDiffWeekOne = (diff / (1000 * 3600 * 24));
        }

      }    
      
      if(this.periodStart != undefined && this.periodEnd != undefined) {

        let currentDiffWeektwo;

        if(this.periodStart == this.periodEnd) {

          currentDiffWeektwo = currentDiffWeekOne;

        } else {

          if(this.periodStart == 'Après 12h') {

            currentDiffWeektwo = currentDiffWeekOne;

          } else {

            currentDiffWeektwo = Number(currentDiffWeekOne) + 1;

          }

        }

        this.dataSearchService.diffDate = currentDiffWeektwo;

      } else {

        this.dataSearchService.diffDate = currentDiffWeekOne;
      }
                       


      this.dataSearchService.address = this.address;

      this.dataSearchService.geometry = this.geometry;

     

      this.dataSearchService.Model = this.modelCar;
      
      this.dataSearchService.templateSearchCar = this.templateSearchCar;

      this.router.navigate(['search-car']);
      
    }

  }


  @HostListener('document:click')
  clickedOut() {

    if(this.showChoice || this.showChoiceEnd) {

      this.index ++

      if(this.index >= 2) {

        this.showChoice = false;

        this.showChoiceEnd = false;

        this.index = 0;

      }

    }
  }


  ngOnDestroy(): void {
    this.dataSearchService.templateSearchCar = this.templateSearchCar;

    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }
}