<div class="container-fluid">
    <div class="row mt-5">
        <div class="col"></div>
        <div class="col-4">
            <div class="card shadow">
                <div class="card-body">
                    <div class="row mt-2">

                        <cool-google-button (click)="signInWithGoogle()">
                            Connexion avec Google
                        </cool-google-button> <br /><br />

                        <cool-facebook-button style="border: light;" (click)="signInWithFacebook()">
                            Connexion avec Facebook
                        </cool-facebook-button><br /><br />

                         <cool-apple-button style="border: light;" (click)="signInWithApple()" >
                            Connexion avec Apple
                        </cool-apple-button> 

                        

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>