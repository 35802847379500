import { Component, Inject, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd, ResolveStart, ResolveEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
   
  public settings: Settings;
 
  constructor(public appSettings:AppSettings, 
              public router:Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService,
              private spinner: NgxSpinnerService,
              private cd:ChangeDetectorRef){
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','de','fr','ru','tr']);
    translate.setDefaultLang('en'); 
    translate.use('en');
  }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      /*if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }); 
      }*/
      switch (true) {
        case event instanceof NavigationEnd: {
          setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0,0);
            }
          }); 
          break;
        }

        case event instanceof ResolveStart: {
          
          this.spinner.show();
          break;
        }
        case event instanceof ResolveEnd: {
          
          this.spinner.hide();
          break;
        }
        
        default: {
          this.spinner.hide();
          break;
        }
      }            
    });    
  }

}
