<h1 mat-dialog-title class="header-dialog"><mat-icon color="primary">tune</mat-icon> Plus de filtres</h1>
<form [formGroup]="form" (ngSubmit)="submitForm()">
    <div mat-dialog-content>
        <div class="container">
            <div class="compartment">
                <h5 class="title-compartment">Capacité de l’utilitaire</h5>
                <div class="boby-compartment-slider">
                    <div class="value-title-slider value-slider-text" *ngIf="valueCapacity == 0">Tous les volumes</div>
                    <div class="value-title-slider value-slider-text" *ngIf="valueCapacity != 0">{{textCapacity}}</div>
                    <mat-slider [color]="color" class="slider" (change)="sliderChange('c1', $event)" min="0" max="30" aria-label="units" [value]="sliderC1"></mat-slider>
                    <mat-slider [color]="color" class="slider" (change)="sliderChange('c2', $event)" min="0" max="30" aria-label="units" [value]="sliderC2"></mat-slider>
                </div>
            </div>
            <div class="compartment">
                <h5 class="title-compartment">Nombre de places</h5>
                <div class="boby-compartment-slider">
                    <div class="value-title-slider value-slider-text" *ngIf="valuePlace == 0">Toutes les places</div>
                    <div class="value-title-slider value-slider-text" *ngIf="valuePlace != 0">{{textNumberPlace}}</div>
                    <mat-slider [color]="color" class="slider" (change)="sliderChange('p1', $event)" min="0" max="9" aria-label="units" [value]="sliderP1"></mat-slider>
                    <mat-slider [color]="color" class="slider" (change)="sliderChange('p2', $event)" min="0" max="9" aria-label="units" [value]="sliderP2"></mat-slider>
                </div>
            </div>
            <div class="compartment">
                <h5 class="title-compartment">Equipements</h5>
                <div class="boby-checkbox">
                    <div *ngFor="let item of equipments" class="current-checkbox">
                        <mat-checkbox [checked]="item.completed" [value]="item.id" (change)="onCheckboxChange('eq', $event)" [color]="color" class="checkbox">{{item.text}}</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="other-compartment">
                <h5 class="title-compartment">Boîte de vitesse</h5>
                <div class="boby-other-compartment">
                    <div class="text-content-other">Automatique ou Manuelle</div>
                    <div>
                        <mat-slide-toggle [color]="color" formControlName="vitesse" (change)="onSlideToggleChange()"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="compartment">
                <h5 class="title-compartment">Type de moteur</h5>
                <div class="boby-checkbox">
                    <div *ngFor="let item of motorType" class="current-checkbox">
                        <mat-checkbox [checked]="item.completed" [value]="item.value" (change)="onCheckboxChange('mr', $event)" [color]="color" class="checkbox">{{item.text}}</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="compartment">
                <h5 class="title-compartment">Age du véhicule</h5>
                <mat-radio-group aria-label="Select an option" class="boby-checkbox" formControlName="year" (change)="onRadioButton()">
                    <mat-radio-button [color]="color" value="all" class="radio">Véhicules de tous âges</mat-radio-button>
                    <mat-radio-button [color]="color" value="2" class="radio">Moins de 2 ans</mat-radio-button>
                    <mat-radio-button [color]="color" value="5" class="radio">Moins de 5 ans</mat-radio-button>
                    <mat-radio-button [color]="color" value="10" class="radio">Moins de 10 ans</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

    </div>
    <div mat-dialog-actions align="end">
        <button class="search-button-disabled" *ngIf="!showButtonSubmit">Rechercher</button>
        <button class="search-button" type="submit" *ngIf="showButtonSubmit">Rechercher</button>
    </div>
</form>