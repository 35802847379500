<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">Evaluations</h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div> 
<mat-dialog-content> 
    <div fxLayout="row wrap" class="mt-3 all-notation">
        <div fxFlex="100">
            <div class="div-rating">
                <div class="div-star">
                    <svg color="currentColor" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:30px" viewBox="0 0 1024 1024">
                        <path d="M512.011 757.336l-263.339 138.452 50.304-293.248-213.333-207.66 294.4-42.666 131.668-266.795 131.672 266.795 294.4 42.666-213.336 207.66 50.304 
                            93.248-262.74-138.452z">
                        </path>
                        <path d="M511.715 48.848c13.919 0 26.631 7.903 32.794 20.386l123.158 249.546 275.361 39.908c13.784 1.998 25.238 11.653 29.539 24.899s0.706 27.787-9.275 
                            37.5l-199.545 194.235 47.053 274.282c2.355 13.729-3.291 27.6-14.563 35.781s-26.211 9.249-38.532 2.757l-245.712-129.481-246.301 129.496c-12.322 6.48-27.253 
                            5.402-38.515-2.783-11.262-8.181-16.902-22.049-14.548-35.771l47.050-274.282-199.544-194.235c-9.98-9.713-13.576-24.254-9.274-37.5s15.754-22.901 29.538-24.899l275.363-39.908 
                            123.156-249.546c6.162-12.483 18.875-20.386 32.797-20.386zM511.715 168.056l-98.875 200.343c-5.332 10.8-15.634 18.282-27.549 20.008l-220.967 32.026 160.162 155.9c8.632 8.404 
                            12.573 20.517 10.536 32.391l-37.775 220.208 197.747-103.969c10.664-5.606 23.406-5.599 34.066 0.018l197.105 103.863-37.76-220.12c-2.037-11.875 1.902-23.987 
                            10.533-32.391l160.165-155.9-220.965-32.026c-11.919-1.726-22.221-9.209-27.549-20.008l-98.875-200.343z">
                        </path>
                    </svg>
                </div>
                <div>
                    <p style="font-size: 1.3rem; margin-left: 5px">{{data.avgNote}} <span class="text-color"> ({{data.sumNote}} Opinion)</span></p>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
    <div fxLayout="row wrap">
        <div fxFlex="100" *ngFor="let notation of notations; last as last" class="p-3">
            <div class="div-comment">
                <div>
                    <img [src]="notation.user_image!=null?host.getHttpsImage()+notation.user_image:'assets/images/avatars/avatar-1.png'" alt="image" class="notation-author">
                </div>
                <div class="div-info">
                    <div class="div-rating">
                        <div class="div-star">
                            <svg color="currentColor" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024">
                                <path d="M512.011 757.336l-263.339 138.452 50.304-293.248-213.333-207.66 294.4-42.666 131.668-266.795 131.672 266.795 294.4 42.666-213.336 207.66 50.304 
                                    93.248-262.74-138.452z">
                                </path>
                                <path d="M511.715 48.848c13.919 0 26.631 7.903 32.794 20.386l123.158 249.546 275.361 39.908c13.784 1.998 25.238 11.653 29.539 24.899s0.706 27.787-9.275 
                                    37.5l-199.545 194.235 47.053 274.282c2.355 13.729-3.291 27.6-14.563 35.781s-26.211 9.249-38.532 2.757l-245.712-129.481-246.301 129.496c-12.322 6.48-27.253 
                                    5.402-38.515-2.783-11.262-8.181-16.902-22.049-14.548-35.771l47.050-274.282-199.544-194.235c-9.98-9.713-13.576-24.254-9.274-37.5s15.754-22.901 29.538-24.899l275.363-39.908 
                                    123.156-249.546c6.162-12.483 18.875-20.386 32.797-20.386zM511.715 168.056l-98.875 200.343c-5.332 10.8-15.634 18.282-27.549 20.008l-220.967 32.026 160.162 155.9c8.632 8.404 
                                    12.573 20.517 10.536 32.391l-37.775 220.208 197.747-103.969c10.664-5.606 23.406-5.599 34.066 0.018l197.105 103.863-37.76-220.12c-2.037-11.875 1.902-23.987 
                                    10.533-32.391l160.165-155.9-220.965-32.026c-11.919-1.726-22.221-9.209-27.549-20.008l-98.875-200.343z">
                                </path>
                            </svg>
                        </div>
                        <div style="margin-left: 2px;">
                            <p>{{notation.notation_note}}</p>
                        </div>
                    </div>
                   
                    <p class="text">{{notation.notation_comment}}</p>
                    <p class="text-muted"><small>{{notation.user_name}}, {{notation.created_at | date: 'd MMM y'}} </small></p>
                </div>
            </div>
            <div class="divider" *ngIf="!last"></div>
        </div>
    </div> 
</mat-dialog-content> 