import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-en-quelques-mots',
  templateUrl: './en-quelques-mots.component.html',
  styleUrls: ['./en-quelques-mots.component.scss']
})
export class EnQuelquesMotsComponent implements OnInit {

  @Input('hostServiceImage') hostServiceImage:any;

  constructor() { }

  ngOnInit(): void {
  }

}
