import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HostService } from './host.service';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  dialogresponse: any;
  elt = [];
  src: any;

  headers = new HttpHeaders();
  private user = [
    {
      id: 1,
     user_name: '',
      cni: '',
      surname: '',
      adresse: '',
      city: '',
      email: '1233@gmail.com',
      tel: '691638011',

    },
  ]
  HttpClient: any;
  constructor(
        private https: HttpClient,
    private matDialog: MatDialog,
    private getHost: HostService
    ) { }
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      return next.handle(req).pipe(
        catchError((error) => {
          console.log('error is intercept')
          console.error(error);
          return throwError(error.message);
        })
      )
    }
    
  CreateUser(user: any) {
    // console.log(customer);

    return this.https.post(this.getHost.gethttps() + 'createUser',user,);
  }
 

  CreateRenting(renting: any) {
    // console.log(customer);
    return this.https.post(this.getHost.gethttps() + 'rentings',renting, );
  }
  CreateEntr(entre: any) {
    // console.log(customer);
    return this.https.post(this.getHost.gethttps() + 'createEntr',entre, );
  }

   getListes() {
    return this.https.get(this.getHost.gethttps() + 'user', );
  }

  delete(idUser: number,): Observable<any> {

    return this.https.post(this.getHost.gethttps() + 'deleteUser', { id: idUser }, );
  }
  
  UpdateUserr(datas: any) {
    return this.https.post(this.getHost.gethttps() + 'updateUser', datas,);
  }
  
  updateSuper(datas: any) {
    return this.https.post(this.getHost.gethttps() + 'updateSuperUser', datas,);
  }

  updateStatusSuper(datas: any) {
    return this.https.post(this.getHost.gethttps() + 'updateStatusUser', datas,);
  }
  
  public getUser(){
 
    return this.https.get(this.getHost.gethttps() + 'indexUser');

  } 

  public getUserOn10Ans(){
 
    return this.https.get(this.getHost.gethttps() + 'index10yearsUser');

  } 

  public getUserFind(datas: any){
 
    return this.https.get(this.getHost.gethttps() + 'findUser/' + datas);

  } 
// enristrer une image
  SaveImg(imgUser: any) {
    return this.https.post(this.getHost.gethttps() + 'SaveImg',imgUser, );
  }

  updateUserRenting(id: number, user: any) {
    return this.https.patch(this.getHost.gethttps() + 'updateUserRenting/' +id , user);
  }

  changeProfil(value: object) {
    return this.https.post(this.getHost.gethttps() + 'changeProfil' , value);
  }

  sendMailUser(object:{[key:string]:any})
  {
    return this.https.post(this.getHost.gethttps() + 'sendEmailUser' , object);
  }


  //Entreprise
  storeEntreprise(entreprise: any) {
    
    return this.https.post(this.getHost.gethttps() + 'storeEntreprise', entreprise);

  }

  //FeedBack
  feedBack(feedBack: any) {

    return this.https.post(this.getHost.gethttps() + 'feedBack', feedBack);

  }

}

