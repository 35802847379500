import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { MenuItem } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { CartOverviewComponent } from '../cart-overview/cart-overview.component';

import { Router } from "@angular/router";
import { DataSearchService } from "src/app/services/data-search.service";
import { VehiculeserviceService } from "src/app/services/vehiculeservice.service";
import { HostService } from "./../../services/host.service";
import { Subscription } from "rxjs";
import { AppSettings, Settings } from "src/app/app.settings";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent implements OnInit {
  @Input() menuItem!: MenuItem;
  @Input() lazyLoad: boolean = false;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: any;
  @Input() carinput: any;
  public column: number = 4;
  constructor(
    public appService: AppService,
    public hostService: HostService,
    private router: Router,
    public car: VehiculeserviceService,
    private dataSearchService: DataSearchService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.viewColChanged) {
      this.getColumnCount(changes.viewColChanged.currentValue);
    }
  }

  public getColumnCount(value: number) {
    if (value == 25) {
      this.column = 4;
    } else if (value == 33.3) {
      this.column = 3;
    } else if (value == 50) {
      this.column = 2;
    } else {
      this.column = 1;
    }
  }
 
  clickCar(event: any) {
   
    this.dataSearchService.idCar = event;

    let currentCar = this.carinput.find((element: any) => element.id == event);
    

    this.dataSearchService.address = currentCar.vehicle_adress;

    this.dataSearchService.lat = currentCar.latitude;

    this.dataSearchService.lng = currentCar.longitude;

    this.dataSearchService.isRentingMap = true;
    this.dataSearchService.idCar = event;

    this.router.navigate(["location-car"]);
  }
}
