import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MenuItem, TemplateSearchCar } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { DataSearchService } from 'src/app/services/data-search.service';
import { GeolocalisationService } from 'src/app/services/geolocalisation.service';
import { HostService } from 'src/app/services/host.service';
import { VehiculeserviceService } from 'src/app/services/vehiculeservice.service';


@Component({
  selector: 'app-model-vehicules',
  templateUrl: './model-vehicules.component.html',
  styleUrls: ['./model-vehicules.component.scss']
})
export class ModelVehiculesComponent implements OnInit {
  
  public config: SwiperConfigInterface = {};
  public modelVoiture:string
  public cars:Array<any>=[];
  viewType: string = "grid";
  public carinput: any;
  dateStart: Date;
  dateEnd : Date;

  public latitude: number;
  public longitude: number;
  zoom: number;
  address: string;

  templateSearchCar:TemplateSearchCar;

  public backgroundImage:any= this.getHost.getHttpsImage() + 'ImageCars/fond.jpg';
  public bgImageAnimate:any=false;
  public contentOffsetToTop:boolean = false;
  public contentMinHeight:any;
  public isHomePage:boolean = false;
  public fullscreen: boolean = false; 
  public menuSearch: boolean = false;
  public bgImage:any;
  public settings: Settings;

  constructor(
    public getHost:HostService, 
    public activatedRoute:ActivatedRoute,
    public car: VehiculeserviceService,
    private dataSearchService: DataSearchService,
    public appService: AppService,
    private router: Router,
    private datePipe: DatePipe,
    private geolocalisationService:GeolocalisationService,
    public appSettings:AppSettings, 
    private sanitizer:DomSanitizer 
  ) {
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.settings.headerBgImage = true;
    }); 
  }

  ngOnInit() {
    this.getMenuItems();
    this.geolocalisationService.getCurrentLocalisationUser()
    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      }); 
    } 
    if(this.backgroundImage){
      this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url('+this.backgroundImage +')'); 
    }

  }
  

  public getMenuItems() {
    this.modelVoiture= this.activatedRoute.snapshot.params['model'];
    if(this.modelVoiture !=undefined)
    {
      this.appService.getCategorieVehicule().forEach(elt=>{
        if(elt.code.toLocaleLowerCase().split(',').indexOf(this.modelVoiture)!=-1 || elt.code.toLocaleLowerCase()===this.modelVoiture){
          this.modelVoiture= elt.code;
        }
      })
    }

    this.car.getallcar().subscribe((data: any) => {
      let vehicule:MenuItem[] = data;
      vehicule.forEach(element => {
        let modelCar=element.vehicle_categorie.toLocaleLowerCase().split(',')
        if(modelCar.indexOf(this.modelVoiture)!=-1 || element.vehicle_categorie===this.modelVoiture){
          this.cars.push(element);
        }
      });
    });
  }

  clickCar(event: any) {
   
    this.dataSearchService.idCar = event;
    let currentCar = this.cars.find((element) => element.id == event);
    

    this.dataSearchService.address = currentCar?.vehicle_adress;

    this.dataSearchService.lat = currentCar?.latitude;

    this.dataSearchService.lng = currentCar?.longitude;

    this.dataSearchService.isRentingMap = true;
    this.dataSearchService.idCar = event;

    this.router.navigate(["location-car"]);
  }

  public search() {
    this.dataSearchService.Model = this.modelVoiture;
    this.router.navigate(['search-car']);
  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView:  4,
      spaceBetween: 8,       
      keyboard: true,
      navigation: { nextEl: '.prop-next', prevEl: '.prop-prev'},
      //pagination: true,
      autoplay:false,
      effect:'slide',
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,   
      breakpoints: {
        280: {
          slidesPerView: 1
        },
        600: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        }
      }
    }
  }

  getcar()
  {
    let currentPosition:any = localStorage.getItem('myCurrentPosition');
    let address = "Côte d'Ivoire";
    let geometry =  {location:{lat:()=>{return 7.665580628948325},lng:()=>{return -5.67440742909365}}}

    if(currentPosition !=undefined || currentPosition!=null)
    {
      currentPosition = JSON.parse(currentPosition);
      address=currentPosition.address;
      geometry =  {location:{lat:currentPosition.lat,lng:currentPosition.long}};
    }

    this.templateSearchCar = {
      address: address, 
      geometry: geometry, 
      dateStart:new Date(Date.now()),
      dateEnd: new Date(Date.now()), 
      periodStart: 'Avant 12h', 
      periodEnd: 'Après  12h'
    }
    this.dataSearchService.Model = this.modelVoiture;
    this.dataSearchService.templateSearchCar = this.templateSearchCar;
    this.router.navigate(['search-car']);
  }

    ngOnDestroy(): void {
      if(this.templateSearchCar !=undefined)
      {
        this.dataSearchService.templateSearchCar = this.templateSearchCar;
      }
      
      setTimeout(() => {
        this.settings.headerBgImage = false; 
        this.settings.contentOffsetToTop = false;
      });  
    }

}
