import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
  @Input() ratingsCount:number = 0;
  @Input() ratingsValue:any = 0; 
  @Input() comment: number;
  @Input() showCar: boolean = false;
  avg:any = 0;
  stars:Array<string> = [];

  myClass = "text";

  constructor() { }

  ngDoCheck() {
    // if(this.ratingsCount && this.ratingsValue && !this.avg) {
    //   this.calculateAvgValue();      
    // }
    this.calculateAvgValue(); 
  }

  public  calculateAvgValue(){
    
    // console.log(this.avg, this.ratingsCount);
    
    this.avg = this.ratingsCount
    switch (true) {
      case this.avg > 0 && this.avg < 1 : {
          this.stars = ['star_half', 'star_border', 'star_border', 'star_border', 'star_border'];
          break;
      }      
      case this.avg == 1 : {
          this.stars = ['star', 'star_border', 'star_border', 'star_border', 'star_border'];
          break;
      }      
      case this.avg > 1 && this.avg < 2 : {
          this.stars = ['star', 'star_half', 'star_border', 'star_border', 'star_border'];
          break;
      }      
      case this.avg == 2 : {
        this.stars = ['star', 'star', 'star_border', 'star_border', 'star_border'];
          break;
      } 
      case this.avg > 2 && this.avg < 3 : {
          this.stars = ['star', 'star', 'star_half', 'star_border', 'star_border'];
          break;
      }
      case this.avg == 3 : {
          this.stars = ['star', 'star', 'star', 'star_border', 'star_border'];
          break;
      }
      case this.avg > 3 && this.avg < 4 : {
          this.stars = ['star', 'star', 'star', 'star_half', 'star_border'];
          break;
      }
      case this.avg == 4 : {
          this.stars = ['star', 'star', 'star', 'star', 'star_border'];
          break;
      } 
      case this.avg > 4 && this.avg < 5 : {
          this.stars = ['star', 'star', 'star', 'star', 'star_half'];
          break;
      } 
      case this.avg >= 5 : {
          this.stars = ['star', 'star', 'star', 'star', 'star'];
          break;
      }   
      default: {
          this.stars = ['star_border', 'star_border', 'star_border', 'star_border', 'star_border'];
          break;
      }
    }
  }

}
