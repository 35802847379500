import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { NumeroCompte } from 'src/app/app.models';
import { LocationSmartService } from 'src/app/services/location-smart.service';
import { NumeroCompteService } from 'src/app/services/numero-compte.service';
import { PaymentService } from 'src/app/services/payment.service';
import { TransferService } from 'src/app/services/transfer.service';

@Component({
  selector: 'app-choose-payment-method',
  templateUrl: './choose-payment-method.component.html',
  styleUrls: ['./choose-payment-method.component.scss']
})
export class ChoosePaymentMethodComponent implements OnInit, OnDestroy {

  title: string;

  numeroCompteSubscription: Subscription;
  numeroCompte: NumeroCompte

  public form!: UntypedFormGroup;
  public paymentMethod: string;

  constructor(public dialogRef: MatDialogRef<ChoosePaymentMethodComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private numeroCompteService: NumeroCompteService,
              private locationSmartService: LocationSmartService, 
              private spinner: NgxSpinnerService,
              private transferService: TransferService,
              private toastr: ToastrService,
              private paymentService: PaymentService,
              public formBuilder: UntypedFormBuilder) {}

  
  ngOnInit(): void {
    
    this.initProperty();

    this.initForm();

  }


  initProperty() {

    if(this.data.key == 'payment') {

      this.title = "Choisissez votre moyen de paiement";

    } else {

      this.title = "Choisissez le moyen par lequel vous souhaitez efectuer le virement";

      this.numeroCompteSubscription = this.numeroCompteService.numeroCompteOfUser(this.data.transfer.user_id).subscribe((response: any) => {
  
        if(response.numeroCompte) {
  
          this.numeroCompte = response.numeroCompte;
  
        } else {
  
          this.numeroCompte = new NumeroCompte({});
  
        }
    
      })

    }

    // console.log(this.data);

  }

  initForm() {
    
    this.form = this.formBuilder.group({
      code: ["", [Validators.required, Validators.pattern('^[0-9]{6}$')]],
    });

  }

  checkMethodPayment(value: string) {
    
    if(this.data.key == 'payment') {

      if(value == 'Asset') {

        this.spinner.show();

        if(this.data.for == 'renting') {

          this.paymentService.paymentOfRentingExist(this.data.id).subscribe((value: any) => {

            // console.log(value);
        
            if(value.val == 1) {
        
              this.toastr.show(
                'Vous avez déjà effectué le paiement de cette location',
                'Paiement evident',
                {
                  timeOut: 6000,
                  closeButton: true,
                } as IndividualConfig,
                'toast-' + 'warning'
              )
        
              this.spinner.hide();

              this.dialogRef.close();

            } else {

              let obj = {
                'idUser': JSON.parse(localStorage.getItem("user")!).id,
                'id': this.data.id,
                'key': this.data.for
              }

              this.paymentService.paymentWithAsset(obj).subscribe((response: any) => {

                // console.log(response);

                if(response.status) {

                  if(!response.rest) {

                    this.toastr.show(
                      response.message,
                      'Paiement réussi ',
                      {
                        timeOut: 6000,
                        closeButton: true,
                      } as IndividualConfig,
                      'toast-' + 'success'
                    )

                  } else {

                    this.toastr.show(
                      response.message,
                      'Reste à payer',
                      {
                        timeOut: 6000,
                        closeButton: true,
                      } as IndividualConfig,
                      'toast-' + 'warning'
                    )

                  }
                  

                } else {

                  this.toastr.show(
                    response.message,
                    'Echec de paiement ',
                    {
                      timeOut: 6000,
                      closeButton: true,
                    } as IndividualConfig,
                    'toast-' + 'error'
                  )

                }

                this.spinner.hide();

                this.dialogRef.close();

              })

            }
          })

        } else {

          this.paymentService.paymentOfExtensionExist(this.data.id).subscribe((value: any) => {

            // console.log(value);
        
            if(value.val == 1) {
        
              this.toastr.show(
                'Vous avez déjà effectué le paiement de cette prolongation',
                'Paiement evident',
                {
                  timeOut: 6000,
                  closeButton: true,
                } as IndividualConfig,
                'toast-' + 'warning'
              )
        
              this.spinner.hide();

              this.dialogRef.close();

            } else {

              let obj = {
                'idUser': JSON.parse(localStorage.getItem("user")!).id,
                'id': this.data.id,
                'key': this.data.for
              }

              this.paymentService.paymentWithAsset(obj).subscribe((response: any) => {

                // console.log(response);

                if(response.status) {

                  if(!response.rest) {

                    this.toastr.show(
                      response.message,
                      'Paiement réussi ',
                      {
                        timeOut: 6000,
                        closeButton: true,
                      } as IndividualConfig,
                      'toast-' + 'success'
                    )

                  } else {

                    this.toastr.show(
                      response.message,
                      'Reste à payer',
                      {
                        timeOut: 6000,
                        closeButton: true,
                      } as IndividualConfig,
                      'toast-' + 'warning'
                    )

                  }
                  

                } else {

                  this.toastr.show(
                    response.message,
                    'Echec de paiement ',
                    {
                      timeOut: 6000,
                      closeButton: true,
                    } as IndividualConfig,
                    'toast-' + 'error'
                  )

                }

                this.spinner.hide();

                this.dialogRef.close();

              })

            }
          })

        }

      } else {

        if(value == "Orange_Money") {

          this.paymentMethod = value;

        } else {

          if(this.data.for == 'renting') {

            this.locationSmartService.payment(this.data.id, value);

          } else {

            this.locationSmartService.paymentExtension(this.data.id, value);

          }

          this.dialogRef.close();

        }
        
      }

    } else {

      let obj = {
        'id': this.data.id,
        'amount': this.data.transfer.transfer_amount,
        'phone': this.data.transfer.numero_compte,
        'paymentMethod': value
      }

      this.spinner.show();

      this.transferService.paymentOfTransfer(obj).subscribe((response: any) => {
       
        response = response['res'];

        if(response != null) {

          if(response['status']) {

            this.toastr.show(
              response['message'],
              'Success Success',
              {
                timeOut: 5000,
                closeButton: true,
              } as IndividualConfig,
              'toast-' + 'success'
            )
    
            this.dialogRef.close(1);

          } else {
  
            this.toastr.show(
              response['message'],
              'Erreur',
              {
                timeOut: 5000,
                closeButton: true,
              } as IndividualConfig,
              'toast-' + 'error'
            )
  
          }

          this.spinner.hide();

          this.dialogRef.close(1);

        } else {
         
          this.toastr.show(
            'Erreur survenue lors de l\'execution de la requête',
            'Erreur',
            {
              timeOut: 5000,
              closeButton: true,
            } as IndividualConfig,
            'toast-' + 'error'
          )

          this.dialogRef.close(0);
          
        }
       

      })

    }
    

  }

  savePaymentOrange() {

    if(this.form.status == "INVALID") {

      this.toastr.error("Veuillez saisir le code réçu par mal");

    } else {

      let obj = {
        "id": this.data.id,
        "paymentMethod": this.paymentMethod,
        "code": this.form.value.code
      }

      this.spinner.show();

      if(this.data.for == 'renting') {

        this.paymentService.create(obj).subscribe((response: any) => {

          if(response['status'] == 1) {
  
            this.toastr.success(response['message']);
  
          } else {
  
            this.toastr.error(response['message']);
            
          }
  
          this.spinner.hide();
  
          this.dialogRef.close();
  
        });

      } else {

        this.paymentService.createExtension(obj).subscribe((response: any) => {

          if(response['status'] == 1) {
  
            this.toastr.success(response['message']);
  
          } else {
  
            this.toastr.error(response['message']);
            
          }
  
          this.spinner.hide();
  
          this.dialogRef.close();
  
        });

      }
      /*this.paymentService.create(obj).subscribe((response: any) => {

        if(response['status'] == 1) {

          this.toastr.success(response['message']);

        } else {

          this.toastr.error(response['message']);
          
        }

        this.spinner.hide();

        this.dialogRef.close();

      });*/

    }
  }


  ngOnDestroy(): void {
    
    if(this.data.key == 'transfer') {

      this.numeroCompteSubscription.unsubscribe();

    }

  }
}
