<div class="pg-home-bg is-grey w-padding">
    <div class="inside-wide with-padding-rwd">
        <div class="display-flex align-space align-stretch with-rwd">
            <section class="pg-home-bottom is-left"> 
                
                <a routerLink="/ConditionAssurance" > 
                    <img [src]="hostServiceImage+'images/categories/suv.jpg'" alt="logo assurnace">
                    </a>
                    <h3 class="pg-home-title" > 4x4</h3>
            </section>
            <section class="pg-home-bottom is-left"> 
                
                <a routerLink="/ConditionAssurance" > 
                    <img [src]="hostServiceImage+'images/categories/berline.jpg'" alt="logo assurnace">
                    </a>
                    <h3 class="pg-home-title">Berline </h3>
            </section>
            <section class="pg-home-bottom is-left"> 
                
                <a routerLink="/ConditionAssurance" > 
                    <img [src]="hostServiceImage+'images/categories/utilitaire.jpg'" alt="logo assurnace">
                    </a>
                    <h3 class="pg-home-title">Utilitaire</h3>
            </section>
            <section class="pg-home-bottom is-left"> 
               
                <div class="logos-container">
                    <a routerLink="/ConditionAssurance" > 
                    <img [src]="hostServiceImage+'images/categories/monosapace.jpg'" alt="logo assurnace">
                    </a>
                </div>
                <h3 class="pg-home-title">Monospaces</h3>
            </section>


            
        </div>
    </div>
</div>