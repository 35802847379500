import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-redirection-extension',
  templateUrl: './payment-redirection-extension.component.html',
  styleUrls: ['./payment-redirection-extension.component.scss']
})
export class PaymentRedirectionExtensionComponent implements OnInit {

  public message: string;
  public status: number;

  constructor(private Activatedroute: ActivatedRoute, private toastr: ToastrService,
              private router: Router, private spinner: NgxSpinnerService) {
                
               }

  ngOnInit(): void {

    this.Activatedroute.data.subscribe(data => {

      this.spinner.hide();
      // console.log(data.paymentResolve, data);
      
      this.message = data.payment?.message;
      this.status = data.payment?.status;

      if(this.status == 1) {

        this.toastr.show(
          this.message,
          'Paiement Valide',
          {
            timeOut: 6000,
            closeButton: true,
          } as IndividualConfig,
          'toast-' + 'success'
        )

        this.router.navigate(['compte-utilisateur/mes-prolongations/locataire']);


      } else {

        this.toastr.show(
          this.message,
          'Echec Paiement',
          {
            timeOut: 6000,
            closeButton: true,
          } as IndividualConfig,
          'toast-' + 'Error'
        )

      } 
      
      // window.location.reload();

    })
  }

}
