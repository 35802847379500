<div fxLayout="row wrap">
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-green tile p-2"> 
            <mat-icon>monetization_on</mat-icon> 
            <h2>{{sumRenting}} {{currency}}</h2>         
            <p>Total Paiement</p>            
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">
        <mat-card class="gradient-orange tile p-2">
            <mat-icon>trending_up</mat-icon> 
            <h2>{{sumTransfer}} {{currency}}</h2>         
            <p>Total Virement</p> 
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-red tile p-2"> 
            <mat-icon>thumb_up_alt</mat-icon> 
            <h2>{{currentAmount}} {{currency}}</h2>         
            <p>Montant encaisse</p>            
        </mat-card>
    </div>
    <!-- <div fxFlex="50" fxFlex.xl="20" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-green tile p-2"> 
            <mat-icon>monetization_on</mat-icon> 
            <h2>{{gain}} {{currency}}</h2>         
            <p>Total des Bénefices pour GalsenAuto</p>             -->
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">
        <mat-card class="gradient-brown tile p-2">
            <mat-icon>monetization_on</mat-icon> 
            <h2>{{sumAsset}} {{currency}}</h2>         
            <p>Montant avoirs</p>  
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-green tile p-2"> 
            <mat-icon>trending_down</mat-icon> 
            <h2>{{waveBalance}} {{currency}}</h2>         
            <p>Solde Wave</p>            
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">
        <mat-card class="gradient-orange tile p-2">
            <mat-icon>trending_down</mat-icon> 
            <h2>{{orangeBalance}} {{currency}}</h2>         
            <p>Solde Orange</p> 
        </mat-card>
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center" class="p-2" class="search">
    <div fxLayout="row">
        <mat-button-toggle-group #controls="matButtonToggleGroup" appearance="legacy">            

            <mat-button-toggle value="search">
                <mat-icon>search</mat-icon>
            </mat-button-toggle>           
        </mat-button-toggle-group>            
    </div>
    <form class="user-search" [class.show]="controls.value == 'search'">
        <mat-form-field class="user-search-input">
            <input matInput placeholder="Recherche un Virement..." (keyup)="applyFilter($event)" name="search">
        </mat-form-field>
    </form>
</div>
<mat-card class="m-2 p-0">
    <div class="table-wrapper"> 
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
        
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let transfer; let i=index"> #{{i+1}} </td>
            </ng-container>  
    
            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant </th>
                <td mat-cell *matCellDef="let transfer"> {{transfer.transfer_amount}} {{currency}}</td>
            </ng-container>  

             <!-- Type Column -->
             <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Motif </th>
                <td mat-cell *matCellDef="let transfer"> 
                    <span *ngIf="transfer.transfer_type == 'RENTING';else elseBlock">Location</span>
                    <ng-template #elseBlock>Avoir</ng-template>
                </td>
            </ng-container> 

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let transfer">
                    <span *ngIf="transfer.transfer_status == 'EN_ATTENTE'" style="color: blue;">En attente de validation</span>
                    <span *ngIf="transfer.transfer_status == 'VALIDER'" style="color: rgb(3, 154, 3);">Validé</span>
                    <span *ngIf="transfer.transfer_status == 'REJETER'" style="color:red;">Rejeté</span>
                    <span *ngIf="transfer.transfer_status == 'EFFECTUER'" style="color: rgb(190, 191, 154);">Effectué</span> 
                </td>
            </ng-container> 

            <!-- Table user Column -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                <td mat-cell *matCellDef="let transfer"> {{transfer.user_name}} {{transfer.user_surname}}</td>
            </ng-container> 

            <!-- Table Number Column -->
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Numéro de compte  </th>
                <td mat-cell *matCellDef="let transfer"> {{transfer.numero_compte}}</td>
            </ng-container> 
    
            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let transfer">{{transfer.created_at | date:"dd MMMM, yyyy 'à' HH:mm" }}</td>
            </ng-container>
            
            <!-- View Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let transfer">
                    <button *ngIf="transfer.transfer_status == 'EN_ATTENTE'" mat-icon-button  color="primary" matTooltip="Approuver la demande de virement" (click)="changeStatus(transfer.id, 'VALIDER')">
                        <mat-icon>check</mat-icon>
                    </button>
                    <button *ngIf="transfer.transfer_status == 'EN_ATTENTE'" mat-icon-button  color="warn" matTooltip="Rejeter la demande de virement" (click)="changeStatus(transfer.id, 'REJETER')">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <button *ngIf="transfer.transfer_status == 'VALIDER'"  mat-icon-button  color="primary" matTooltip="Effectuer le virement" (click)="choosePaymentMethod(transfer)">
                        <mat-icon>account_balance_wallet</mat-icon>
                    </button>
                </td>
            </ng-container> 
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let transfer; columns: displayedColumns;"></tr>
        </table> 
        <p *ngIf="dataSource && dataSource.data.length === 0" class="py-5 mb-0 text-center">No data available</p> 
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
</mat-card>