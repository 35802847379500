import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Country } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { HostService } from 'src/app/services/host.service';
import { RentingService } from 'src/app/services/renting.service';
import { AllVehicleRatingsComponent } from '../comments/dialog/all-vehicle-ratings/all-vehicle-ratings.component';

@Component({
  selector: 'app-renting-message',
  templateUrl: './renting-message.component.html',
  styleUrls: ['./renting-message.component.scss']
})

export class RentingMessageComponent implements OnInit, OnDestroy {

    //Subscription
    rentingSubscription: Subscription;

    public message: string;
    public user: any;
    public country: Country | undefined;

    public notations: any[] = [];

    constructor(public dialogRef: MatDialogRef<RentingMessageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private rentingService: RentingService,
              public hostService: HostService, private appService: AppService,
              public host: HostService) { }

    ngOnInit(): void {

      let obj = {
        'idRenting': this.data.idRenting,
        'key': this.data.key
      };

      this.rentingSubscription = this.rentingService.showInfoUser(obj).subscribe((response: any) => {
        
        this.user = response.infoUser;

        this.country = this.appService.getCountries().find((country: Country) => country.code == this.user.user_country);

        this.notations = response.notations;

        // console.log(response, this.notations);
      })
    }

    onConfirm(): void { 
    this.dialogRef.close(true);
    }

    onDismiss(): void { 
    this.dialogRef.close(false);
    }

    getAllNotation() {

      let data = {
        avgNote: this.user.note,
        sumNote: this.user.sumNotice,
        key: 'user',
        idUser: this.user.user_id
      }
      
      const dialogRef = this.appService.openDialog(AllVehicleRatingsComponent, data, 'theme-dialog', '500px');
      
    }

    ngOnDestroy(): void {
      
      this.rentingSubscription.unsubscribe();

    }

}
