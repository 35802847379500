import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { RentingService } from 'src/app/services/renting.service';

@Component({
  selector: 'app-renting-list',
  templateUrl: './renting-list.component.html',
  styleUrls: ['./renting-list.component.scss']
})
export class RentingListComponent implements OnInit, OnDestroy {

  @Input('status') status: string;

  rentingsSubscriptions: Subscription;

  displayedColumns: string[] = ['id', 'period_renting', 'amount', 'amount_owner', 'car', 'status', 'logder', 'owner', 'date'];
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;


  public searchText: string = '';

  currentDate: string | null;

  currency: string;

  constructor(private rentingService: RentingService, private datePipe: DatePipe,
              private appService: AppService) { }

  ngOnInit(): void {
    
    this.initProperty();

  }

  initProperty() {

    this.currency = this.appService.getCurrency();

    this.rentingsSubscriptions  = this.rentingService.index(this.status).subscribe((rentings: any) => {

      // console.log(rentings);

      this.dataSource = new MatTableDataSource(rentings.rentings);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort; 

    })

    this.currentDate =  this.datePipe.transform(Date.now(), 'yyyy-MM-dd')

  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

  } 

  ngOnDestroy(): void {
    
    this.rentingsSubscriptions.unsubscribe();

  }

}
