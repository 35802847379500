import { Component, OnInit, Input } from '@angular/core';
import { HostService } from 'src/app/services/host.service';

@Component({
  selector: 'app-about-umexi',
  templateUrl: './about-umexi.component.html',
  styleUrls: ['./about-umexi.component.scss']
})
export class AboutUmexiComponent implements OnInit {
  
  
  constructor(public getHost:HostService) {}

  ngOnInit() {}
}
