<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">
            <span>Prolongation d'une réservation</span>
        </h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div> 
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off" class="pt-3">
<mat-dialog-content>  
    <div class="pt-3"> 
        <div fxLayout="row wrap" class="flex-wrapper">
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">DU</h2>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="px-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Date de Début</mat-label>
                    <input matInput readonly disabled autocomplete="off" class="form-input date" data-testid="google-map-input" name="search-address" placeholder="Date de départ" type="text" [matDatepicker]="datePickerStart" appMaskDate
                    [value]="data.renting_date_return">
                    <mat-datepicker-toggle matSuffix [for]="datePickerStart">
                        <mat-icon matDatepickerToggleIcon>
                            today
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datePickerStart></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="px-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Période</mat-label>
                    <input matInput readonly  [value]="periodStart">
                </mat-form-field>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">AU</h2>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="px-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Date de Fin</mat-label>
                    <input matInput readonly autocomplete="off" class="form-input date" data-testid="google-map-input" name="search-address" placeholder="Date de retour" type="text" [matDatepicker]="datePickerEnd" appMaskDate
                      [min]="minDateEnd" [matDatepickerFilter]="myFilter" formControlName="dateEnd" (dateChange)="dateEndChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="datePickerEnd">
                        <mat-icon matDatepickerToggleIcon>
                            event
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #datePickerEnd></mat-datepicker>
                </mat-form-field> 
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3" *ngIf="showSelectOption">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Période de retour</mat-label>
                    <mat-select placeholder="" formControlName="periodEnd">
                        <mat-option [value]="'Av 12h'" (click)="returnPeriod('Av 12h')">Avant 12h</mat-option>
                        <mat-option [value]="'Ap 12h'" (click)="returnPeriod('Ap 12h')">Après 12h</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3" *ngIf="returnPeriodValue">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Heure de retour</mat-label>
                    <mat-select placeholder="" formControlName="hourEnd" *ngIf="returnPeriodValue == 'Av 12h'">
                        <mat-option [value]="hour.value" *ngFor="let hour of hoursBefore">{{hour.text}}</mat-option>
                    </mat-select>
                    <mat-select placeholder="" formControlName="hourEnd" *ngIf="returnPeriodValue == 'Ap 12h'">
                        <mat-option [value]="hour.value" *ngFor="let hour of hoursAfter">{{hour.text}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>     
    </div>  
</mat-dialog-content> 
<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100"> 
        <button mat-raised-button color="warn" type="button" mat-dialog-close>Annuler</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="valid">Enregistrer</button>
    </div> 
</div>
</form>