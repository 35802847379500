import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-paiement',
  templateUrl: './image-paiement.component.html',
  styleUrls: ["./image-paiement.component.scss"],
})
export class ImagePaiementComponent {
  @Input('key') key:string;
 }
