import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ExtensionService } from 'src/app/services/extension.service';
import { VehiculeserviceService } from 'src/app/services/vehiculeservice.service';

@Component({
  selector: 'app-renting-extension',
  templateUrl: './renting-extension.component.html',
  styleUrls: ['./renting-extension.component.scss']
})
export class RentingExtensionComponent implements OnInit, OnDestroy {

  //Subscription
  extensionSubscrition: Subscription;

  public dateLocked: Array<string> = [];

  public periodStart: string = '';
  public minDateEnd: Date = new Date();
  public dateReturnPlusOneDay: Date;
  public returnPeriodValue: string | null;

  public hoursBefore: Array<any>;
  public hoursAfter: Array<any>;

  public form!: UntypedFormGroup;

  public showSelectOption: boolean;

  public valid: boolean = false


  constructor(public dialogRef: MatDialogRef<RentingExtensionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private vehicleService: VehiculeserviceService,
              public appService: AppService, public fb: UntypedFormBuilder,
              private toastr: ToastrService, private datePipe: DatePipe,
              private extensionService: ExtensionService, private router: Router) { }

  ngOnInit(): void {

    this.initForm();

    this.initProperty();

  }

  initProperty() {

    //Date de retour de la réservation initiale en chaîne de caractère
    let dateStartToString = this.datePipe.transform(this.data.renting_date_return, 'yyyy-MM-dd')!;

    //Date de retour de la réservation initiale plus un jour
    this.dateReturnPlusOneDay = new Date(this.data.renting_date_return);

    this.dateReturnPlusOneDay.setDate(this.dateReturnPlusOneDay.getDate()+1);


    /**
    * Nous recuperons les dates bloquées pour les reservations
    */
    let obj = {
      'idVehicle': this.data.vehicle_id,
      'idRenting': this.data.id
    }

    this.extensionSubscrition = this.vehicleService.lockedRentingDateForVehicule(obj).subscribe((data: any) => {

      this.dateLocked = data.dates;

      //Nous regardons si le jour suivant la fin de la réservation n'est pas déjà occupé.
      let dateReturnPlusOneDayToString = this.datePipe.transform(this.dateReturnPlusOneDay, 'MM/dd/yyyy');
      
      let currentDate = this.dateLocked.find((date) => {return date == dateReturnPlusOneDayToString});

      if(currentDate) {

        this.toastr.error("Impossible d'effectuer une prolongation pour ce véhicule car la date suivant le "+dateStartToString+" a déjà été réservé");

        this.dialogRef.close()

      } else {

        //Pour afficher la selection de la periode et de l'heure de retour 
        this.showSelectOption = true;


        //Intervalle d'heure pour une réservation
        this.hoursBefore = this.appService.getHoursBefore();

        this.hoursAfter = this.appService.getHoursAfter()

        
        //Nous recherchions la période de fin de la réservation
        let value = this.appService.getHoursBefore().find((hour) => {
          return hour['value'] == this.data.renting_hour_return;
        });

        if(value) {

          this.periodStart = 'Av 12h';

          this.minDateEnd = this.data.renting_date_return;

        } else {

          this.periodStart = 'Ap 12h';

          this.minDateEnd = this.dateReturnPlusOneDay;

        }

      }

    });

  }

  initForm() {

    //Initialisation du formulaire
    this.form = this.fb.group({
      dateEnd: ['', Validators.required],
      periodEnd: [''],
      hourEnd: ['']
    }); 

  }

  myFilter = (d: Date | null): boolean => {

    const time=(d || new Date()).getTime();

    return !this.dateLocked.find((x: string | number | Date)=>new Date(x).getTime()==time);

  }

  /**
   * Contrôler effectuer sur la date de fin de la location
   * @param event 
   */
  dateEndChange(event: any) {

    let currentDateFormat = this.datePipe.transform(event.target.value, 'yyyy-MM-dd')!
    
    if(currentDateFormat == this.data.renting_date_return) {

      this.showSelectOption = false;

      this.returnPeriodValue = 'Ap 12h'

      this.form.patchValue({'periodEnd': 'Ap 12h'});

    } else {

      this.showSelectOption = true;

      this.returnPeriodValue = null;

      this.form.patchValue({'periodEnd': '', 'hourEnd': ''});

    }

    // console.log('je suis la ', this.returnPeriodValue);

  }

  /**
   * Period de retour
  */
  returnPeriod(value: string) {

    this.returnPeriodValue = value;

  }

  onSubmit() {

    //Validation du formulaire

    if(this.showSelectOption && this.form.value.periodEnd != '' && this.form.value.hourEnd != '') {

      this.valid = true;

    } else if(!this.showSelectOption && this.form.value.periodEnd != '' && this.form.value.hourEnd != '') {

      this.valid = true;

    } else {

      this.valid = false;

    }

    if(this.valid) {

      let obj;
      //Date de retour en chaîne de caractère
      let dateReturnToString = this.datePipe.transform(this.form.value.dateEnd, 'yyyy-MM-dd')!;
      
      if(!this.showSelectOption) {

        //Prolongement pour le même jour

        obj = {
          'extension_date_return': dateReturnToString,
          'extension_hour_return': this.form.value.hourEnd,
          'extension_number_day': 1,
          'extension_price': this.data.price_location,
          'renting_id' : this.data.id
        }

      } else {

        //Nombre de jour pour la prolongation
        let diff = this.form.value.dateEnd.getTime() - new Date(this.data.renting_date_return).getTime();
      
        let numberOfDays: number;

        numberOfDays = (diff / (1000 * 3600 * 24));

        if(this.periodStart == this.form.value.periodEnd) {

          numberOfDays = Number(numberOfDays.toFixed(0));

        } else {

          if(this.periodStart == 'Ap 12h') {

            numberOfDays = Number(numberOfDays.toFixed(0));

          } else {

            numberOfDays = Number((numberOfDays + 1).toFixed(0));

          }

        }

        //Prix du prolongement
        let currentPrice = Number(Number(this.data.price_location) * Number(numberOfDays)).toFixed(0);
  
        obj = {
          'extension_date_return': dateReturnToString,
          'extension_hour_return': this.form.value.hourEnd,
          'extension_number_day': numberOfDays,
          'extension_price': currentPrice,
          'renting_id' : this.data.id
        }

      }
      
      this.extensionSubscrition =  this.extensionService.create(obj).subscribe((response) => {

        this.toastr.success('La prolongation a été enregistré avec success');

        this.dialogRef.close();

        this.router.navigate(['compte-utilisateur/mes-prolongations/locataire']);

      })

    } else {

      this.toastr.warning('Veuillez remplir tous les champs du formulaire');

    }
  }


  ngOnDestroy(): void {
    
    this.extensionSubscrition.unsubscribe();

  }

}
