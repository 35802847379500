import { Component, OnInit } from "@angular/core";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { Output, EventEmitter } from "@angular/core";
import { AppService } from "src/app/app.service";

@Component({
  selector: "app-search-adresse-accident",
  templateUrl: "./search-adresse-accident.component.html",
  styleUrls: ["./search-adresse-accident.component.scss"],
})
export class SearchAdresseAccidentComponent implements OnInit {
  //Local Variable Defined
  @Output() newItemEvent = new EventEmitter<any>();
  address: string;
  geometry: object;

  options: Options;



  constructor(
    private appService: AppService
  ) {
    this.options = this.appService.getOptionPlacesMap();
  }

  ngOnInit(): void {
    
  }

  public addressChange(address: any) {
    this.address = address.formatted_address;
    let obj = {
      adress: this.address,
      latitude: address.geometry.location.lat(),
      lonitude: address.geometry.location.lng(),
    };

    this.geometry = address.geometry.location;

    this.newItemEvent.emit(obj);
  }

}