

<!-- <div fxLayout="row wrap">
    <div fxFlex="100" fxFlex.gt-sm="50">

    </div>
    <div fxFlex="100" fxFlex.gt-sm="50">

    </div>
</div> -->




<div class="section default mobile-app mt-0 pb-0">
    <div class="header-image-wrapper">
        <div class="bg" [style.background-image]="bgImage"></div>
        <div class="mask"></div>
        <div class="header-image-content" fxLayout="column" fxLayoutAlign="center center">
            <h4 class="tile title-galsen">Vous n'utilisez pas votre voiture tous les jours? Louez-la sur GalsenAuto!</h4>
            <p class="desc-galsen">Inscrivez votre voiture gratuitement, louez-la quand vous voulez et remboursez vos frais d'entretien. Rassurez-vous, c'est AXA qui assure toutes les locations.
            </p>
            <div ngClass.gt-xs="mt-4" ngClass.xs="mt-3">
                <a routerLink="/compte-utilisateur/mes-vehicules/liste-vehicule" mat-raised-button color="primary">{{ 'Louer ma voiture' | translate }}</a>
            </div>
        </div>
    </div>
</div>
<!-- <div class="header-image-wrapper">
    <div class="bg" [style.background-image]="bgImage"></div>
    <div class="mask"></div>
    <div class="header-image-content" fxLayout="column" fxLayoutAlign="center center">
        
    </div>
</div> -->