import { Injectable } from '@angular/core';
import { HostService } from './host.service';
import { HttpClient } from '@angular/common/http';
import { Extension } from '../app.models';
import { Cacheable } from "ts-cacheable"


@Injectable({
  providedIn: 'root'
})
export class ExtensionService {

  constructor(private getHost: HostService, private http: HttpClient) { }

  create(extension: Extension) {

    return this.http.post(this.getHost.gethttps() + "extension", extension);

  }

  //Prolongation Locataire
  indexLodger(idUser: number) {

    return this.http.get(this.getHost.gethttps() + "extensionLodger/" + idUser);

  }

  //Prolongation proprietaire
  indexOwner(idUser: number) {

    return this.http.get(this.getHost.gethttps() + "extensionOwner/" + idUser);

  }

  //Change status
  changeStatus(extension: any) {

    return this.http.post(this.getHost.gethttps() + "changeStatusExtension", extension);

  }

  @Cacheable()
  checkIfExtensionExist($idRenting: number) {

    return this.http.get(this.getHost.gethttps() + "checkIfExtensionExist/"+$idRenting);

  }

}
