import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'carPipes', pure: false })

export class carPipes implements PipeTransform {
  transform(value:any, args?:any) {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter((car:any) => {
        console.log(car)
       
        console.log( car.mod_lib)
        if ( car.mark_lib) {
          return car.mark_lib.search(searchText) !== -1;
        }
        else{
          return car.mod_lib.search(searchText) !== -1;
        }
        
      });
    }
  }
}