<div class="pg-home-bg is-grey w-padding">
    <div class="inside-wide with-padding-rwd">
        <div class="display-flex align-space align-stretch with-rwd">
            <section class="pg-home-bottom is-left"> <br/><br/>
                <h3 class="pg-home-title" >Des questions <mat-icon>contact_support</mat-icon></h3>
                <p class="txt fz-18"  style="text-align: center;">
                    Notre équipe est à votre disposition tous les jours au
                    <!-- -->&nbsp; <br>
                    <span class="fwb secondarycolor"> <mat-icon>perm_phone_msg</mat-icon> 682 79 24 83</span>.
                </p>
                <div class="fz-14 lightmorecolor m-2" fxLayout="row wrap">
                    <div fxFlex="100" fxFlex.gt-sm="50">DU</div>
                    <div fxFlex="100" fxFlex.gt-sm="50">DE</div>
                </div>
                <div class="fz-14 lightmorecolor" fxLayout="row wrap">
                    <div fxFlex="100" fxFlex.gt-sm="45">Du lundi au vendredi de 8h30 à 18h30.</div>
                    <div fxFlex="100" fxFlex.gt-sm="10"><mat-divider [vertical]="true"></mat-divider></div>
                    <div fxFlex="100" fxFlex.gt-sm="45">Samedi, dimanche et jour fériés de 8h30 à 18h30.</div>
                </div>
            </section>
            <section class="pg-home-bottom is-right">
                <h3 class="pg-home-title">Toutes les locations sont assurées par NSIA</h3>
                <div class="display-flex align-space align-top with-rwd">
                    <div class="txt fz-18 mr3">
                        Assurance incluse dans le prix. <br> Couverture multirisque accident et vol.<br> Assistance dépannage 24h/24 pour tous les véhicules.
                    </div>
                    <div class="logos-container">
                        <a routerLink="/ConditionAssurance" > 
                        <img [src]="hostServiceImage+'images/others/assurance.png'" alt="logo assurnace">
                        </a>
                        
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>