import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-insurance',
  templateUrl: './question-insurance.component.html',
  styleUrls: ['./question-insurance.component.scss']
})
export class QuestionInsuranceComponent implements OnInit {

  @Input('hostServiceImage') hostServiceImage:any;

  constructor() { }

  ngOnInit(): void {
  }

}
