import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu-items-toolbar',
  templateUrl: './menu-items-toolbar.component.html',
  styleUrls: ['./menu-items-toolbar.component.scss']
})
export class MenuItemsToolbarComponent implements OnInit {
  @Output() onSidenavToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeCount: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeViewType: EventEmitter<any> = new EventEmitter<any>();
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public counts = [8, 12, 16, 24, 36];
  public count:any;
  public sort:any;
  
  constructor() { }

  ngOnInit() {

    this.count = this.counts[1];

  }

  ngOnChanges(){
    // console.log(' show toggle - ' ,this.showSidenavToggle)
  }

  public changeCount(count:number){
    this.count = count;
    this.onChangeCount.emit(count);
    // this.getAllProducts(); 
  }

  applyFilter(filtervalue: any) {

    this.onChangeSearch.emit(filtervalue.target.value);

  }

  public changeViewType(viewType:any, viewCol:any){
    this.viewType = viewType;
    this.viewCol = viewCol;
    this.onChangeViewType.emit({viewType:viewType, viewCol:viewCol});
  }

  public sidenavToggle(){
    this.onSidenavToggle.emit();
  }

}
