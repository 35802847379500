import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from "@angular/material/core";
import { ActivatedRoute, Router } from '@angular/router';
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotationCarService } from 'src/app/services/notation-car.service';

interface Rating {
  value: number;
  max: number;
  color?: ThemePalette;
  disabled?: boolean;
  dense?: boolean;
  readonly?: boolean;
};

@Component({
  selector: "app-notation-car",
  templateUrl: "./notation-car.component.html",
  styleUrls: ["./notation-car.component.scss"],
})
export class NotationCarComponent implements OnInit, OnDestroy {

  subscriptionNotationExiste: Subscription;

  public form: FormGroup;
  public code:any;
  private id: string;
  public data:any;
  private sub: any;
  private user: any

  public actifButtom: boolean;
  private notationExist: boolean = false;

  constructor(private fb: FormBuilder, private Notationservice: NotationCarService, 
              public router: Router, private route: ActivatedRoute,
              private toastr: ToastrService, private authService: AuthService
    ) {}

  ngOnInit(): void {

    let res = this.authService.getUserOfLocalStorage();
    
    if(res.status) {

      this.user = JSON.parse(localStorage.getItem("user")!)

      this.checkIfNotationExist();


    } else {

      this.notationExist = false;

      this.router.navigate(['login']);

    }
    
    this.initForm();
  
  }

  checkIfNotationExist() {

    this.id = this.route.snapshot.paramMap.get('id')!; 

    let obj = {
      'idRenting': this.id,
      'idUser': this.user.id
    }
    
    this.subscriptionNotationExiste = this.Notationservice.notationExist(obj).subscribe((response: any) => {
      
      this.notationExist = true;
      
      if(response.value) {
        this.toastr.show(
          "Une notation a déjà été établi pour cette réservation",
          "Impossible Impossible",
          {
            timeOut: 10000,
            closeButton: true,
          } as IndividualConfig,
          "toast-" + "warning"
        );

        this.actifButtom = false;

        this.router.navigate(['/']);

      } else {

        this.actifButtom = true;
        
      }
    });

  }

  initForm() {

    this.form = this.fb.group({
      rating: ["", Validators.required],
      notation_description: [""],
    });

  }

  notationCarSubmit() {

    let obj = {
      'note' : this.form.value.rating,
      'comment': this.form.value.notation_description,
      'renting_id': this.id,
      'user_id': this.user.id
    };
    
    this.subscriptionNotationExiste =  this.Notationservice.create(obj).subscribe((data) => {
      
      this.toastr.show(
        "votre notation a été enregistrée avec succès",
        "notation enregistrée",
        {
          timeOut: 5000,
          closeButton: true,
        } as IndividualConfig,
        "toast-" + "success"
      );

       this.router.navigate(["/"]);

    });

  }

  ngOnDestroy(): void {
    
    if(this.notationExist) {

      this.subscriptionNotationExiste.unsubscribe();

    }

  }
}
