<div *ngFor="let item of specialMenuItems" fxFlex="100" class="p-2" id="stylediv">

    <mat-card class="menu-item p-0" [fxLayout]="(viewType == 'grid') ? 'column' : 'row'"
        [ngClass]="[viewType + '-item', 'column-'+column]" class="card">
        <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">
            <mat-chip-list *ngIf="menuItem.discount">
                <mat-chip color="warn" selected="true">{{menuItem.discount}}% OFF</mat-chip>
            </mat-chip-list>
            <div *ngIf="lazyLoad">
                <img *ngIf="!lazyLoad" src={{hostService.getHttpsImage()+item.title_image}} class="w-100 d-block">

            </div>

            <img *ngIf="lazyLoad" src={{hostService.getHttpsImage()+item.title_image}} class="w-100 d-block" height="250" width="200px" >
        </div>
        <div fxFlex="100" class="p-3">
            <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
                <mat-card-content class="mb-0">
                    <h1 class="title"><a [routerLink]="['/menu', item.id]">{{item.mod_lib}}</a></h1>
                <mat-accordion>
                    <mat-expansion-panel class="mat-expansion">
                        <mat-expansion-panel-header>
                            <mat-panel-title fxLayout="row" fxLayoutAlign="space-between">
                                <div class="fw-500">Description</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p>{{menuItem.vehicle_description}} </p>
                    </mat-expansion-panel>
                </mat-accordion>
                </mat-card-content>
                <div class="sticky-footer-helper" fxFlex="grow"></div>
                <mat-card-actions class="m-0 p-0">
                    <div class="features">
                        <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                            <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                            <span class="px-1">Categorie:</span>
                            <span class="dots"></span>
                            <span class="fw-500">{{ item.mark_lib }}</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                            <mat-icon color="primary" class="mat-icon-sm">{{ (menuItem.availibilityCount > 0) ?
                                'check_circle' : 'unpublished'}}</mat-icon>
                            <span class="px-1">Disponibilité:</span>
                            <span class="dots"></span>
                            <span class="fw-500">{{ item.status}}</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                            <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                            <span class="px-1">Nbre places:</span>
                            <span class="dots"></span>
                            <span class="fw-500">{{ item.number_place_limit }}</span>
                        </p>

                    </div>

                    <div fxLayout="row" [fxLayoutAlign]="(viewType == 'grid') ? 'space-between center' : 'end center'"
                        class="mt-3">
                        <span class="text-muted">Avis Client<span *ngIf="viewType == 'list'"
                                class="px-2">:</span></span>
                    <app-rating [ratingsCount]='item.note' ratingsValue=2 [showCar]="true"></app-rating>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">

                        <h2 *ngIf="!menuItem.discount" class="primary-color">
                            {{item.vehicle_price_location}} FCFA
                        </h2>
                        <h2 *ngIf="menuItem.discount">
                            <span class="text-muted old-price">{{item.vehicle_price_location}} FCFA </span>
                        </h2>
                       
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">
                        <a (click)="clickCar(item.id)" mat-raised-button color="primary" class="transform-none">Reserver</a>
                    
                    </div>



                </mat-card-actions>
            </div>
        </div>
    </mat-card>
</div>




