<div *ngIf="blockMenuUser == false || !authService.isLoggedIn" id="blockMenuUser">
    <div class="responsive_menu-item">
        <a mat-button routerLink="/entreprise">
            <span fxShow="false" fxShow.gt-sm class="mx-1 uppercase">Offre entreprise</span>
        </a>
        <a mat-button routerLink="/login">
            <span fxShow="false" fxShow.gt-sm class="mx-1 uppercase">Louer ma voiture</span>
        </a>
        <a mat-button [matMenuTriggerFor]="menu">Mon Compte <mat-icon>arrow_drop_down</mat-icon></a>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/login">Se connecter</button>
            <button mat-menu-item routerLink="/create-utilisateurs">A S'inscrire</button>
        </mat-menu>
        <app-lang class="responsive_menu-item"></app-lang>
    </div>
    <div class="responsive_menu">
        <button mat-icon-button (click)="openSideNav()" >
            <mat-icon>menu</mat-icon>
          </button>
    </div>
</div>
<div *ngIf="blockMenuUser == true && authService.isLoggedIn" id="blockMenuUser">
    <a mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
        <mat-icon class="mat-icon-sm">person</mat-icon>
        <span fxShow="false" fxShow.gt-sm class="mx-1 uppercase">Mon Espace</span>
        <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a> 

    <mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-menu-panel user-menu">
        <span (mouseleave)="userMenuTrigger.closeMenu()">

    <div fxLayout="row" fxLayoutAlign="start" class="user-info" style="background-color: #1565c0;">
        <div fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100p" fxFlex.sm="100" fxFlex.xs="100">
            <div Align="Center">
                <div fxLayout="row">
                    <div fxFlex="100">
                        <img alt="profile image" src={{src+UserImage}} width="50" 
                        *ngIf="elementImage" id="elementImage" style="text-align: center;">
                        <img alt="profile image" [src]="UserImage"
                        width="50" *ngIf="!elementImage" id="elementImage" style="text-align: center;">
                    </div>
                </div>
                <p class="mb-0" *ngIf="userDetails?.user_name" style="color: white;">{{userDetails?.user_name}}</p>
                <small style="color: white;" *ngIf="userDetails?.user_phone">{{userDetails?.user_phone}}</small><br>
                <small style="color: white;">{{userDetails?.email}}</small><br>
              
            </div>
        </div>

    </div>
    <div class="divider"></div>
    <a mat-menu-item routerLink="/compte-utilisateur">
        <mat-icon class="mat-icon-sm">account_circle</mat-icon>
        <span>Mon compte</span>
    </a>
    <a mat-menu-item routerLink="/compte-utilisateur/message">
        <mat-icon class="mat-icon-sm">mail_outline</mat-icon>
        <span>Mes Messages</span>
    </a>
    <a mat-menu-item routerLink="/compte-utilisateur/mes-paiements/mes-paiements">
        <mat-icon class="mat-icon-sm">money</mat-icon>
        <span>Mes paiements</span>
    </a>
    <a mat-menu-item routerLink="/faq">
        <mat-icon class="mat-icon-sm">help</mat-icon>
        <span>Help</span>
    </a>
    <div class="divider"></div>
    <a mat-menu-item routerLink="/" (click)="logout()">
        <mat-icon class="mat-icon-sm">lock</mat-icon>
        <span>Se déconnecter</span>
    </a>
    <div class="divider"></div>
        </span>
    </mat-menu>

</div>

 

